import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { CreditNoteConstant } from "./CreditNoteConstant";

const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Detail;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_CREDIT_NOTE_DETAIL_COL_DEF: any[] = [
    {
        headerName: CREDIT_NOTE_CONSTANT.STATE,
        field: 'dtlState',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CREDIT_DETAIL_NO,
        field: 'creditDetailNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.INVOICE_NO,
        field: 'invoiceNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.ORIG_CHARGING_QTY,
        field: 'oriChargingQty',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 230,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.ORIG_AMOUNT,
        field: 'oriAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CREDIT_CHARGING_QTY,
        field: 'creditChargingQty',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CHARGE_UOM,
        field: 'individualChargeQtyUom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CREDIT_AMOUNT,
        field: 'creditAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAR_TYPE,
        field: 'tariffType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAR_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAR_DESC,
        field: 'tarDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.DETAIL_DESC1,
        field: 'dtlDesc1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.DETAIL_DESC2,
        field: 'dtlDesc2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.OPS_DATE,
        field: 'opsDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.ADJ_TYPE,
        field: 'adjType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CREDIT_NOTE_NATURE,
        field: 'creditNoteNature',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.REVENUE_AC_CODE,
        field: 'revenueAccountCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.COST_CENTER_CODE,
        field: 'costCenterCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.PRODUCT_SERVICE_CODE,
        field: 'productServiceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 230,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.SALES_CHANNEL_CODE,
        field: 'salesChannelCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.COUNTRY_CODE,
        field: 'countryCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.INTER_COMPANY_CODE,
        field: 'intercompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 230,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.PROJECT_CODE,
        field: 'projectCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.SPARE_CODE,
        field: 'spareCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.REVENUE_COMPANY_CODE,
        field: 'revenueCompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_CODE,
        field: 'taxCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_PENCENTAGE,
        field: 'vatPercent',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_REVENUE_AC_CODE,
        field: 'vatRevenueACCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 230,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_COST_CENTER_CODE,
        field: 'vatCostCenterCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_PRODUCT_SERVICE_CODE,
        field: 'vatProductServiceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 230,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_SALES_CHANNEL_CODE,
        field: 'vatSalesChannelCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 230,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_COUNTRY_CODE,
        field: 'vatCountryCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_INTER_COMPANY_CODE,
        field: 'vatIntercompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_PROJECT_CODE,
        field: 'vatProjectCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_SPARE_CODE,
        field: 'vatSpareCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_COMPANY_CODE,
        field: 'vatCompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TRANSACTION_TYPE,
        field: 'vatTransactionType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_PAYMENT_TERM,
        field: 'vatDays',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_PAYMENT_REFERENCE_DATE,
        field: 'vatDateType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 280,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.ARTICLE_STATEMENT_CODE,
        field: 'vatArticleStatementCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 230,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.ARTICLE_STATEMENT,
        field: 'vatArticleStatement',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_DETAIL_DESC1,
        field: 'vatDtlDesc1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_DETAIL_DESC2,
        field: 'vatDtlDesc2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_TARIFF_TYPE,
        field: 'vatTariffType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_TARIFF_CODE,
        field: 'vatTariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAX_TARIFF_CODE_DESC,
        field: 'vatTariffCodeDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CREDIT_NOTE_NO,
        field: 'creditNoteNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CHARGE_IND,
        field: 'chargeInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.REASON_CODE,
        field: 'reasonCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.EXCEPTION_MSG,
        field: 'exceptionMsg',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};
    cellRenderers['dtlState'] = cellRenderWithColorAndCircle;
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
    }
);

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    externalFnctions['dtlState'] = calculateStateColor;
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}