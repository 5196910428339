export interface InvoiceRecordSearchCriteria{
    
    invoiceNo?: string | null,
    docDateFrom?: Date | null,
    docDateTo?: Date | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_INVOCIE_RECORD_SEARCH_CRITERIA : InvoiceRecordSearchCriteria = {

    invoiceNo: null,
    docDateFrom: null,
    docDateTo: null,
}