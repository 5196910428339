import { CreditNoteDetailEntity, EMPTY_CREDIT_NOTE_DETAIL_ENTITY } from "domain/entity/CreditNote/CreditNoteDetailEntity";
import { CreditNoteHeaderEntity, EMPTY_CREDIT_NOTE_HEADER_ENTITY } from "domain/entity/CreditNote/CreditNoteHeaderEntity";
import { CreditNoteInvoiceDtlRecordEntity } from "domain/entity/CreditNote/CreditNoteInvoiceDtlRecordEntity";
import { CreditNoteInvoiceRecordEntity } from "domain/entity/CreditNote/CreditNoteInvoiceRecordEntity";
import { EMPTY_INVOCIE_RECORD_SEARCH_CRITERIA, InvoiceRecordSearchCriteria } from "domain/entity/CreditNote/InvoiceRecordSearchCriteria";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface CreditNoteDetailDropdownOptions {
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    interCompanyCodeDropdownOptions: DropdownProps[],
    taxInterCompanyCodeDropdownOptions: DropdownProps[],
    taxCodeDropdownOptions: DropdownProps[],
    taxTariffTypeDropdownOptions: DropdownProps[],
    taxTariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    allTariffCodeeDropdownOptions: DropdownProps[],
    chargeIndDropdownOptions: DropdownProps[],
    reasonCodeDropdownOptions: DropdownProps[],
    articleStatementCodeDropdownOptions: DropdownProps[],
    creditNoteNatureDropdownOptions: DropdownProps[],
    operatingTmlDropdownOptions: DropdownProps[],
    reqNoDropdownOptions: DropdownProps[],
    currencyCodeDropdownOptions: DropdownProps[],
    customerCodeDropdownOptions: DropdownProps[],
    billToCompDropdownOptions: DropdownProps[],
}
export interface CreditNoteDetailChangeState extends BaseViewChangeSate {
    currentCreditNoteDtl: CreditNoteDetailEntity,
    isShowHeaderInfo: boolean,
    isShowDetailInfo: boolean,
    isDetailAddClick: boolean,
    isByManual: boolean,
    isByInvoice: boolean,
    isShowInvoicePanel: boolean,
    isShowTariffItemPanel: boolean,
    isSearchInvoice: boolean,
    editingHeader: CreditNoteHeaderEntity,
    
}

export interface CreditNoteDetailModel {
    isLoading:boolean,
    currentCreditNoteHdr: CreditNoteHeaderEntity;
    dynamicOptions: CreditNoteDetailDropdownOptions,
    creditNoteDetailState: CreditNoteDetailChangeState,
    creditNoteDetails: CreditNoteDetailEntity[],
    selectedcreditNoteDetailRows: CreditNoteDetailEntity[],
    invoiceRecordList: CreditNoteInvoiceRecordEntity[],
    selectInvoiceRecords: CreditNoteInvoiceRecordEntity[],
    invoiceRecordCriteria: InvoiceRecordSearchCriteria,
    selectInvoiceDtlRecords: CreditNoteInvoiceDtlRecordEntity[],
    invoiceDtlRecordList: CreditNoteInvoiceDtlRecordEntity[],

}

export const EMPTY_CREDIT_NOTE_DETAIL_MODEL: CreditNoteDetailModel = {
    dynamicOptions: {
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        interCompanyCodeDropdownOptions: [],
        taxInterCompanyCodeDropdownOptions: [],
        taxCodeDropdownOptions: [],
        taxTariffTypeDropdownOptions: [],
        taxTariffCodeDropdownOptions: {},
        allTariffCodeeDropdownOptions: [],
        chargeIndDropdownOptions: [],
        reasonCodeDropdownOptions: [],
        articleStatementCodeDropdownOptions: [],
        creditNoteNatureDropdownOptions: [],
        operatingTmlDropdownOptions: [],
        reqNoDropdownOptions: [],
        currencyCodeDropdownOptions: [],
        customerCodeDropdownOptions: [],
        billToCompDropdownOptions: [],
    },
    creditNoteDetailState: {
        currentCreditNoteDtl: { ...EMPTY_CREDIT_NOTE_DETAIL_ENTITY },
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        isDetailAddClick: false,
        isShowHeaderInfo: true,
        isShowDetailInfo: false,
        isSearchInvoice: false,
        editingHeader: { ...EMPTY_CREDIT_NOTE_HEADER_ENTITY },
        isByManual: false,
        isByInvoice: false,
        isShowInvoicePanel: false,
        isShowTariffItemPanel: false,
    },
    isLoading: false,
    currentCreditNoteHdr: { ...EMPTY_CREDIT_NOTE_HEADER_ENTITY },
    creditNoteDetails: [],
    selectedcreditNoteDetailRows: [],
    invoiceRecordList: [],
    selectInvoiceRecords: [],
    invoiceRecordCriteria: { ...EMPTY_INVOCIE_RECORD_SEARCH_CRITERIA },
    selectInvoiceDtlRecords: [],
    invoiceDtlRecordList: []
}