import { useCreditNoteDetailVM } from 'presentation/hook/CreditNote/useCreditNoteDetailVM';
import { useCreditNoteHeaderMaintenanceTracked } from 'presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import CreditNoteDetailContent from './CreditNoteDetailContent';
import CreditNoteDetailTitleBar from './CreditNoteDetailTitleBar';
import { CreditNoteHeaderFieldFormPanel } from './Form/CreditNoteHeaderFieldFormPanel';
const CreditNoteDetailMaintenance: React.FC = () => {
    const creditNoteDetailVM = useCreditNoteDetailVM();
    const [ creditNoteHeaderState ] = useCreditNoteHeaderMaintenanceTracked();
    const [isLoading, setIsLoading] = useState(true);
    const { currentCreditNoteHdr } = creditNoteHeaderState;
    const { billToCompDropdownOptions } = creditNoteHeaderState.dynamicOptions;
    
    const initialScreen = useCallback(async() => {
        setIsLoading(true);
        try {
            const results = await Promise.allSettled([
                creditNoteDetailVM.onPageInit(currentCreditNoteHdr,billToCompDropdownOptions),
                creditNoteDetailVM.loadDropdownOption(),
            ]);
            results.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {
                    console.log(`Load CreditNoteDetailMaintenance successful!`);
                  } else if (index === 1 && result.status === 'rejected') {
                    console.error(`Load dropdown options successful!`);
                  }
            });
            setIsLoading(false);
        } catch(error) {
        }
    }, [billToCompDropdownOptions, creditNoteDetailVM, currentCreditNoteHdr]);

    useEffect(() => {
            initialScreen();
    }, [initialScreen])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className="main-comp-wrapper">
        <CreditNoteDetailTitleBar/>
            <SliderPanel
                        isOpen={true}
                        draggable={false}
                        leftSectionWidth={"25%"}
                        rightSectionWidth={"75%"}
                        leftChildren={<CreditNoteHeaderFieldFormPanel/>}
                        rightChildren={<CreditNoteDetailContent/>} 
                        />
        </div>
    </>
}
export default memo(CreditNoteDetailMaintenance);