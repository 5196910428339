export const VATPaymentReferenceDatePolicy =() => {
    const DUE_DATE = "DUE_DATE";
    const DOC_DATE = "DOC_DATE";
    const OPS_DATE = "OPS_DATE";
    // const DUE_DATE_DB = "DOC_ADJ_DUE_DATE";
    // const DOC_DATE_DB = "DOC_ADJ_DOC_DATE";
    // const OPS_DATE_DB = "DOC_ADJ_OPS_DATE";
    
    const getKeyByText = (text: string) => {
        if(!text){
            return null;
        }
        if (DUE_DATE === text) {
            return DUE_DATE;
        } else if (DOC_DATE === text) {
            return DOC_DATE;
        } else if (OPS_DATE === text) {
            return OPS_DATE;
        } 
        return null;
    }
    return {
        getKeyByText: getKeyByText
    }
}
