import { useCreditNoteDetailTracked } from "presentation/store/CreditNote/CreditNoteDetailProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { CreditNoteDetailFormPanel } from "./Form/CreditNoteDetailFormPanel";
import InvoiceRecordsRightPanel from "./RightPanel/InvoiceRecordsRightPanel";
import CreditNoteDetailTablePanel from "./Table/CreditNoteDetailTablePanel";

const CreditNoteDetailContent:React.FC = () => {
    const [ creditNoteDetailState ] = useCreditNoteDetailTracked();
    const {isShowDetailInfo,isShowInvoicePanel} = creditNoteDetailState.creditNoteDetailState;

    const rightPanel = useMemo(() => {
        if(isShowDetailInfo){
            return <CreditNoteDetailFormPanel/>;
        }
        if(isShowInvoicePanel){
            return <InvoiceRecordsRightPanel/>;
        }
    }, [isShowDetailInfo, isShowInvoicePanel]);

    
    return <div className={`main-comp-wrapper${isShowDetailInfo ? '' : ' im-hide-side-form-draggable'}`}>
            <SliderPanel
                isOpen={true}
                leftSectionWidth={(isShowDetailInfo || isShowInvoicePanel) ? '30%' : "100%"}
                rightSectionWidth={(isShowDetailInfo || isShowInvoicePanel) ? '70%': '0%'}
                draggable={true}
                leftChildren={<CreditNoteDetailTablePanel/>}
                rightChildren={rightPanel} />
        </div>
}

export default memo(CreditNoteDetailContent);