export interface CreditNoteHeaderEnabledSearchCriteria {
    all: boolean,
    states: boolean,
    billToCompanies: boolean,
    reqNoFrom: boolean,
    reqNoTo: boolean,
    coVslVoy: boolean,
    creditNoteNo: boolean,
    originalInvoiceNo: boolean,
    creditChargeNoFrom: boolean,
    creditChargeNoTo: boolean,
    createdBy: boolean,
    createdDate: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_CREDIT_NOTE_HEADER_SEARCH_CRITERIA: CreditNoteHeaderEnabledSearchCriteria = {
    all: true,
    states: true,
    billToCompanies: true,
    reqNoFrom: true,
    reqNoTo: true,
    coVslVoy: true,
    creditNoteNo: true,
    originalInvoiceNo: true,
    creditChargeNoFrom: true,
    creditChargeNoTo: true,
    createdBy: true,
    createdDate: true,
}