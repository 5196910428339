import { useCreditNoteDetailVM } from "presentation/hook/CreditNote/useCreditNoteDetailVM";
import { useCreditNoteDetailTracked } from "presentation/store/CreditNote/CreditNoteDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { memo, useCallback, useMemo } from "react";
import { HPHButton, InputField } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const GetRecordsByInvoiceNoTitleBar:React.FC = () => {
    const creditNoteDetailVM = useCreditNoteDetailVM();
    const [ creditNoteDetailState ] = useCreditNoteDetailTracked();
    const {invoiceRecordCriteria} = creditNoteDetailState;



    
    const handleReset = useCallback(() => {
        creditNoteDetailVM.invoiceSearchReset();
    },[creditNoteDetailVM]);

    const handleSearch = useCallback(async() => {
        creditNoteDetailVM.onShowLoading();
        creditNoteDetailVM.getInvoiceRecords(invoiceRecordCriteria).then((data) => {            
            creditNoteDetailVM.onHideLoading();
          }).catch(error => {            
            creditNoteDetailVM.onHideLoading();
          });
    }, [creditNoteDetailVM, invoiceRecordCriteria]);
    

    const memoInvoiceNo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={'Doc. No.'}
                type="text"
                value={invoiceRecordCriteria.invoiceNo || ''}
                onChange={(e: any) => creditNoteDetailVM.onDocNoTextInputChange(e)}/>
        </div>
    , [creditNoteDetailVM, invoiceRecordCriteria.invoiceNo])

    const memoCreatedDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={'Doc. Date Range'}
                width='390px'
                fieldNames={{startField:"docDateFrom", endField:"docDateTo"}}
                dateRange={{startDate:invoiceRecordCriteria.docDateFrom, endDate:invoiceRecordCriteria.docDateTo}}
                onDatesChange={creditNoteDetailVM.onDateRangeChange}
            />                
        </div>
    , [creditNoteDetailVM.onDateRangeChange, invoiceRecordCriteria.docDateFrom, invoiceRecordCriteria.docDateTo])



    return <Sidebarheader style={{width: '100%'}}>
        <CriteriaItemContainer>{memoCreatedDateFromTo}{memoInvoiceNo}
        </CriteriaItemContainer>
        <StyledAction className="tm-animated-wrapper">
        <HPHButton disabled={false} label={'Reset'} size={'Small'} theme={'Secondary'} onClick={handleReset} />
        <HPHButton disabled={false} label={'Search'} size={'Small'} theme={'Primary'} onClick={handleSearch} />
        </StyledAction>
    </Sidebarheader>
}

export default memo(GetRecordsByInvoiceNoTitleBar);