import { SelectionChangedEvent } from "ag-grid-community";
import { CreditNoteDetailEntity, EMPTY_CREDIT_NOTE_DETAIL_ENTITY } from "domain/entity/CreditNote/CreditNoteDetailEntity";
import { INITIAL_CREDIT_NOTE_DETAIL_COL_DEF, transferRowData } from "presentation/constant/CreditNote/CreditNoteDetailColumnDefinition";
import { useCreditNoteDetailVM } from "presentation/hook/CreditNote/useCreditNoteDetailVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCreditNoteDetailTracked } from "presentation/store/CreditNote/CreditNoteDetailProvider";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader, OverflowingSubMenu } from "veronica-ui-component/dist/component/core";
const CreditNoteDetailTablePanel = () => {   
    const [ creditNoteDetailState ] = useCreditNoteDetailTracked();
    const creditNoteDetailVM = useCreditNoteDetailVM();
    const gridRef: any = useRef(null);
    const {currentCreditNoteHdr} = creditNoteDetailState;
    const {isRead,isShowDetailInfo} = creditNoteDetailState.creditNoteDetailState;
    const [anaInfoState] = useANAInfoTracked();
    const countryCode = anaInfoState.country+'D'??"";//= "PAK";
    const companyCode = anaInfoState.defaultOperatingCompany??"";//= "2691";
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const {allowCreate} = anaInfoState;
    // const [showMenu, setShowMenu] = useState<boolean>(false);

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_CREDIT_NOTE_DETAIL_COL_DEF.slice());
        
        // if (!creditNoteDetailState.selectedcreditNoteDetailRows ||
        //     creditNoteDetailState.selectedcreditNoteDetailRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        creditNoteDetailVM.updateSelectedCharges(selectedRows);
      }, [creditNoteDetailVM])
      
    const handleDetailAddByInvoiceClick = useCallback(async () => {
        creditNoteDetailVM.onByInvoiceClick();
    }, [creditNoteDetailVM])
    
    const handleDetailAddByManualClick = useCallback(async () => {
        creditNoteDetailVM.onDetailAddClick();
        await creditNoteDetailVM.refreshDefaultTaxCode(countryCode,companyCode,false,{...EMPTY_CREDIT_NOTE_DETAIL_ENTITY});
    }, [companyCode, countryCode, creditNoteDetailVM])

    const handleRowClick = useCallback((creditNoteDtl: CreditNoteDetailEntity) => {
        creditNoteDetailVM.onRowClick(creditNoteDtl);
    }, [creditNoteDetailVM])


    const [isLoading, setIsLoading] = useState(false);
    
    const initialScreen = useCallback(async() => {
        setIsLoading(true);
        try {
            const results = await Promise.allSettled([
                currentCreditNoteHdr?.id && creditNoteDetailVM.onSearch(currentCreditNoteHdr),
            ]);
            results.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {
                    console.log(`Load CreditNoteDetails successful!`);
                  } else if (index === 1 && result.status === 'rejected') {
                    console.error(`Load dropdown options successful!`);
                  }
            });
            setIsLoading(false);
        } catch(error) {
        }
    }, [creditNoteDetailVM, currentCreditNoteHdr]);

    useEffect(() => {
            initialScreen();
    }, [initialScreen])

    // const handleAddClickMenu = useCallback((event:any) => {
    //     setShowMenu(!showMenu);
    // },[showMenu]);
    

    const memoCreditNoteDetailTable = useMemo(() => {

        const addItemList: OverflowingSubMenu[] = [{
                title: 'By Invoice',
                command:handleDetailAddByInvoiceClick
            },
            {
                title: 'By Manual',
                command:handleDetailAddByManualClick
            }
        ];

        return (
        <div id="myDiv">            
        <HPHTable
            id='credit-note-detail-table'
            isNewColumnSetting={true}
            columns={INITIAL_CREDIT_NOTE_DETAIL_COL_DEF.slice()}
            headerLabel={allowCreate && !isRead && !isShowDetailInfo && <OverflowMenuButton icon={"Icon-add"} toolTipText="Add" navigationList={addItemList} className={"title-overflowMenu-forCreditDetail"} />}
            data={transferRowData(creditNoteDetailState.creditNoteDetails??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            // onAddClick={handleAddClickMenu}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            rowSelection={"multiple"}
            onSelectionChanged={handleSelectionChange}
            onRowClick={(e: any) => handleRowClick(e.data)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
        />
        </div>
        );
    },[handleDetailAddByInvoiceClick, handleDetailAddByManualClick, allowCreate, isRead, isShowDetailInfo, creditNoteDetailState.creditNoteDetails, handleSelectionChange, handleRowClick])

    return <>
    {/* {showMenu && <OverflowingMenuWSubMenu disabled={isRead || isShowDetailInfo} items={addItemList} className={"title-overflowMenu"} showArrow />} */}
    <TableWrapper>
        {(isLoading|| creditNoteDetailState.isLoading) && <Loader Indicator="Spinner" size="Medium" /> }        
        {memoCreditNoteDetailTable}
    </TableWrapper></>;
}

export default memo(CreditNoteDetailTablePanel);