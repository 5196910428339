import { RequisitionFormEntity } from "domain/entity/RequisitionForm/RequisitionFormEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { RequisitionFormRepository } from "./RequisitionFormRepo";

export const RequisitionFormRepoImpl = ():RequisitionFormRepository => {
    const url = '/v1/getRequisitionFormForComboBox'
    const getRequisitionFormForComboBox = async(currencyCode: string) : Promise<RequisitionFormEntity[]> => {
        return axiosGetData(chargeAxiosInstance, `${url}/${currencyCode}`,[]).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        })
    }

    return{
        getRequisitionFormForComboBox: getRequisitionFormForComboBox
    }
}