import { SelectionChangedEvent } from "ag-grid-community";
import { CreditNoteInvoiceDtlRecordEntity } from "domain/entity/CreditNote/CreditNoteInvoiceDtlRecordEntity";
import { CreditNoteConstant } from "presentation/constant/CreditNote/CreditNoteConstant";
import { INITIAL_CREDIT_NOTE_INVOICE_DTL_RECORD_COL_DEF } from "presentation/constant/CreditNote/CreditNoteInvoiceDtlRecordColumnDefinition";
import { transferRowData } from "presentation/constant/CreditNote/CreditNoteInvoiceRecordColumnDefinition";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useCreditNoteDetailVM } from "presentation/hook/CreditNote/useCreditNoteDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCreditNoteDetailTracked } from "presentation/store/CreditNote/CreditNoteDetailProvider";
import { useCallback, useEffect, useRef, useState } from "react";
import { HPHButton, HPHTable, Loader } from "veronica-ui-component/dist/component/core";
import { StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const TariffItemsByInvoiceTablePanel = () => {
  const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Invoice;
  const [ creditNoteDetailState ] = useCreditNoteDetailTracked();
  const creditNoteDetailVM = useCreditNoteDetailVM();
  const [anaInfoState] = useANAInfoTracked();
  const {allowUpdate} = anaInfoState;
  const {selectInvoiceRecords,selectInvoiceDtlRecords,creditNoteDetails} = creditNoteDetailState;
  const gridRef: any = useRef(null);
  const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const messageBarVM = useMessageBarVM();

  const handleApply = useCallback(async() => {
    if(selectInvoiceRecords.length > 0 && selectInvoiceDtlRecords.length > 0){
      let zeroAmtBean = [];
      let docHdrId = selectInvoiceRecords[0].id;
      let isCreditNoteInv = "CN" === selectInvoiceRecords[0].docType;
      if(isCreditNoteInv){
        messageBarVM.showWarining("Cannot credit the credit note invoice.");
          return;
      }
      for(let i = 0; i < selectInvoiceDtlRecords.length; i++){
        if("adj" === selectInvoiceDtlRecords[i].flag){
          // messageBarVM.showWarining("The gray background color is indicated document have been adjusted.");
          messageBarVM.showWarining("The selected document have been adjusted.");
          return;
        }
        if(selectInvoiceDtlRecords[i].origChargeAmount === null || selectInvoiceDtlRecords[i].origChargeAmount === 0){
          zeroAmtBean.push(selectInvoiceDtlRecords[i]);
        }
      }
      // selectInvoiceDtlRecords.forEach((dtl) =>{
      //   if("adj" === dtl.flag){
      //     // messageBarVM.showWarining("The gray background color is indicated document have been adjusted.");
      //     messageBarVM.showWarining("The selected document have been adjusted.");
      //     return;
      //   }
      //   if(dtl.origChargeAmount === null || dtl.origChargeAmount === 0){
      //     zeroAmtBean.push(dtl);
      //   }
      // });
      if(zeroAmtBean.length > 0){
        messageBarVM.showWarining("The selected credit tariff item of non-billable will not be saved.");
        if(zeroAmtBean.length === selectInvoiceDtlRecords.length){
          return;
        }
      }
      creditNoteDetailVM.onShowLoading();
      await creditNoteDetailVM.onAddDtlByInvoiceApply(creditNoteDetailState.currentCreditNoteHdr, creditNoteDetails,docHdrId,selectInvoiceDtlRecords).then((data) =>{
        if(data !== "success"){
          creditNoteDetailVM.onHideLoading();
          messageBarVM.showWarining(data??"");
          return;
        }
        creditNoteDetailVM.onHideLoading();
      });

    }
  }, [creditNoteDetailState.currentCreditNoteHdr, creditNoteDetailVM, creditNoteDetails, messageBarVM, selectInvoiceDtlRecords, selectInvoiceRecords]);

  const isDisableApply = () => {
    return selectInvoiceDtlRecords.length === 0;
  }


  const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
    setOnTableSelectionClicked(true);
    const selectedRows = e.api.getSelectedRows();
    creditNoteDetailVM.updateSelectedInvoiceDtlRecordCharges(selectedRows);
  }, [creditNoteDetailVM])
  
  const handleRowClick = useCallback((invoiceDtlRecord: CreditNoteInvoiceDtlRecordEntity) => {
    creditNoteDetailVM.updateSelectedInvoiceDtlRecordCharges([invoiceDtlRecord]);
  },[creditNoteDetailVM])

  useEffect(() => {
    const columnDefs = (INITIAL_CREDIT_NOTE_INVOICE_DTL_RECORD_COL_DEF.slice());
    
    gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
    if (!onTableSelectionClicked) {
        gridRef.current?.gridRef.current.api?.deselectAll();
    }
})

useEffect(() => {
  if (!onTableSelectionClicked) return;
  
  if (selectInvoiceRecords && selectInvoiceRecords.length === 1) {
    setIsLoading(true);
    const selectedInvoice = selectInvoiceRecords[0];
    creditNoteDetailVM.handleInvoiceRowClick(selectedInvoice).finally(() => {
      setIsLoading(false);
    })
  }
}, [creditNoteDetailVM, onTableSelectionClicked, selectInvoiceRecords])

return <div className="side-form-content-wrapper" style={{height:"50vh", maxHeight:"50vh", overflow:"auto", }}>
    {(isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
    <HPHTable
        id='invoice-dtl-records-table'
        isNewColumnSetting={true}
        columns={INITIAL_CREDIT_NOTE_INVOICE_DTL_RECORD_COL_DEF.slice()}
        headerLabel={CREDIT_NOTE_CONSTANT.TARIFF_ITEM_LIST}
        onRowClick={(e: any, invoiceDtlRecord: CreditNoteInvoiceDtlRecordEntity) => handleRowClick(invoiceDtlRecord)}
        data={transferRowData(creditNoteDetailState.invoiceDtlRecordList??[])}
        showPaginator={false}
        editable={false}
        showAddIcon={false}
        showDeleteButton={false}
        showReloadIcon={false}
        isScrollHighlighted={true}
        selectionMode={false}
        rowSelection={"multiple"}
        showActionButtons={true}
        onSelectionChanged={handleSelectionChange}
        gridHeight="customHeight"
        customHeight="calc(33vh)" 
        ref={gridRef}
    />
        {
          // <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center", justifyContent: "flex-end"}}>
            <StyledAction className="tm-animated-wrapper">
                {(allowUpdate) && <HPHButton disabled={isDisableApply()} label={WorkspaceConstant.Common.BUTTON_UPDATE} size={'Small'} theme={'Primary'} onClick={handleApply} />}
            </StyledAction>
          // </Sidebarheader>
        }
  </div>
}
