import { EMPTY_CREDIT_NOTE_DETAIL_MODEL } from "presentation/model/CreditNote/CreditNoteDetailModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: CreditNoteDetailProvider,
    useTracked: useCreditNoteDetailTracked
} = createContainer(() => useState(EMPTY_CREDIT_NOTE_DETAIL_MODEL), {concurrentMode: true});
export { CreditNoteDetailProvider, useCreditNoteDetailTracked };

