export interface CoaEntity {
    id: number | null,
	chartOfAccountId: number | null,
	glCodeCombinationId: number | null,
	revenueCompanyCode: string | null,
	revenueAccountCode: string | null,
	costCenterCode: string | null,
	productServiceCode: string | null,
	salesChannelCode: string | null,
	countryCode: string | null,
	intercompanyCode: string | null,
	projectCode: string | null,
	spareCode: string | null,

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_COA_ENTITY : CoaEntity = {
    id: null,
    chartOfAccountId: null,
    glCodeCombinationId: null,
    revenueCompanyCode: null,
    revenueAccountCode: null,
    costCenterCode: null,
    productServiceCode: null,
    salesChannelCode: null,
    countryCode: null,
    intercompanyCode: null,
    projectCode: null,
    spareCode: null
}