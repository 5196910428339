export interface CreditNoteHeaderEntity {
    id: number | null,
    reqFormId: number | null,
    hdrState: string | null,
    reqNo: string | null,
    chargeType: string | null,
    billToCompany: string | null,
    customerCode: string | null,
    currencyCode: string | null,
    consortiumCode: string | null,
    vesselCode: string | null,
    voyageCode: string | null,
    vesselName: string | null,
    handlingTerminal: string | null,
    etd: Date | null,
    poNo: string | null,
    yourRef: string | null,
    ourRef: string | null,
    slVesselCode: string | null,
    slIbVoyageCode: string | null,
    slObVoyageCode: string | null,
    operatingTml: string | null,
    origDocNo: string | null,
    creditNo: string | null,
    draftCreditNo: string | null,
    creditChargeNo: string | null,
    totalAmount: number | null,
    createdBy: string | null,
    createdDateTime: Date | null,
    docDate: Date | null,
    hdrDesc1: string | null,
    hdrDesc2: string | null,
    reqFormState: string | null,

    [key: string]: string | number | boolean | null | Object | undefined
}

export const EMPTY_CREDIT_NOTE_HEADER_ENTITY : CreditNoteHeaderEntity = {
    id: null,
    reqFormId: null,
    hdrState: "NIL",
    reqNo: null,
    chargeType: "CR",
    billToCompany: null,
    customerCode: null,
    currencyCode: null,
    consortiumCode: null,
    vesselCode: null,
    voyageCode: null,
    vesselName: null,
    handlingTerminal: null,
    etd: null,
    poNo: null,
    yourRef: null,
    ourRef: null,
    slVesselCode: null,
    slIbVoyageCode: null,
    slObVoyageCode: null,
    operatingTml: null,
    origDocNo: null,
    creditNo: null,
    draftCreditNo: null,
    creditChargeNo: null,
    totalAmount: null,
    createdBy: null,
    createdDateTime: null,
    docDate: null,
    hdrDesc1: null,
    hdrDesc2: null,
    reqFormState: null,

}