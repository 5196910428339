import { CreditNoteDetailEntity } from "domain/entity/CreditNote/CreditNoteDetailEntity";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { CreditNoteConstant } from "presentation/constant/CreditNote/CreditNoteConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useCreditNoteDetailVM } from "presentation/hook/CreditNote/useCreditNoteDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCreditNoteDetailTracked } from "presentation/store/CreditNote/CreditNoteDetailProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, HPHButton } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CreditNoteDetailTitleBar:React.FC = () => {
    const creditNoteDetailVM = useCreditNoteDetailVM();
    const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Title;
    const [ creditNoteDetailState ] = useCreditNoteDetailTracked();
    const messageBarVM = useMessageBarVM();
    const {isRead} = creditNoteDetailState.creditNoteDetailState;
    const [anaInfoState] = useANAInfoTracked();
    const {allowUpdate,allowDelete} = anaInfoState;
    const [ isShowDelete, setIsShowDelete ] = useState<boolean>(false);

    // const handleClose = useCallback(() => {
    //     creditNoteDetailVM.onCloseClick();
    // }, [creditNoteDetailVM]);

    
    const handleApply = useCallback(async() => {
        creditNoteDetailVM.onShowLoading();
        creditNoteDetailVM.onApply(creditNoteDetailState.creditNoteDetails).then(async (data) =>{
            if(data){
                await creditNoteDetailVM.onSearch(creditNoteDetailState.currentCreditNoteHdr);
                creditNoteDetailVM.onHideLoading();
                messageBarVM.showSuccess("Apply Successful.");
            }else{
                await creditNoteDetailVM.onSearch(creditNoteDetailState.currentCreditNoteHdr);
                creditNoteDetailVM.onHideLoading();
                messageBarVM.showError("Apply Falied.");
            }
        });
    }, [creditNoteDetailState.creditNoteDetails, creditNoteDetailState.currentCreditNoteHdr, creditNoteDetailVM, messageBarVM]);
    
    const deleteRebateValidate = useCallback((dtls: CreditNoteDetailEntity[],allDtls: CreditNoteDetailEntity[]) => {
        return true;
    }, []);

    const handleDelete = useCallback(async() => {
        setIsShowDelete(false);
        if(!creditNoteDetailState.selectedcreditNoteDetailRows || creditNoteDetailState.selectedcreditNoteDetailRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            creditNoteDetailVM.onHideLoading();
            return;
        }
        if(creditNoteDetailState.creditNoteDetails.length === 1){
            messageBarVM.showWarining("There is only one credit charge detail, it is not allowed to delete.");
            creditNoteDetailVM.onHideLoading();
            return;
        }
        if(creditNoteDetailState.selectedcreditNoteDetailRows.length === creditNoteDetailState.creditNoteDetails.length){
            messageBarVM.showWarining("Cannot delete all credit charge detail.");
            creditNoteDetailVM.onHideLoading();
            return;
        }
        creditNoteDetailVM.onShowLoading();
        let listCreditDetail: CreditNoteDetailEntity[] = [];
        creditNoteDetailState.creditNoteDetails.forEach((dtl) =>{
            if(!dtl.isDiscountItem && dtl.rebateInd !== "Y"){
                listCreditDetail.push(dtl);
            }
        });
        let listSelectedDetail: CreditNoteDetailEntity[] = [];
        let listSelectedDetailId:number[] = [];
        let listSelectedDiscount: CreditNoteDetailEntity[] = [];
        creditNoteDetailState.selectedcreditNoteDetailRows.forEach((dtl) =>{
            if(!dtl.isDiscountItem && dtl.rebateInd !== "Y"){
                listSelectedDetail.push(dtl);
                listSelectedDetailId.push(dtl.id);
            }
        });
        creditNoteDetailState.selectedcreditNoteDetailRows.forEach((dtl) =>{
            if(dtl.isDiscountItem || dtl.rebateInd === "Y"){
                if(listSelectedDetailId.length > 0){
                    if(!listSelectedDetailId.includes(dtl.id)){
                        listSelectedDiscount.push(dtl);
                    }
                }else{
                    listSelectedDiscount.push(dtl);
                }
            }
        });
        if(listCreditDetail.length === listSelectedDetail.length){
            messageBarVM.showWarining("Cannot delete all credit charge detail.");
            creditNoteDetailVM.onHideLoading();
            return;
        }
        if(listSelectedDetail.length > 0){
            let dtlItem: (number | null)[] = [];
            let dtlSelectId: number[]= [];
            let dtlSelect: (number | null)[] = [];
            listSelectedDetail.forEach((ety) =>{
                dtlSelect.push(ety.creditItemId);
                dtlSelectId.push(ety.id);
            });
            listCreditDetail.forEach((ety) =>{
                if(!dtlSelect.includes(ety.creditItemId)){
                    dtlItem.push(ety.itemCreditChargeDtlId);
                }
            });
            let isBoolean = true;
            dtlItem.forEach((ety) =>{
                if(dtlSelectId && ety && !dtlSelectId.includes(ety)){
                    isBoolean = false;
                }
            });
            if(isBoolean){
                messageBarVM.showWarining("There is only one credit charge detail, it is not allowed to delete.");
                creditNoteDetailVM.onHideLoading();
                return;
            }
        }
        if(!deleteRebateValidate(creditNoteDetailState.selectedcreditNoteDetailRows,creditNoteDetailState.creditNoteDetails)){
            creditNoteDetailVM.onHideLoading();
            return;
        }

        creditNoteDetailVM.onDelete(listSelectedDetail,listSelectedDiscount).then((data)=>{
            if(data.success){
                messageBarVM.showSuccess("Delete record successful.");
                creditNoteDetailVM.onSearch(creditNoteDetailState.currentCreditNoteHdr).then(()=>{
                    creditNoteDetailVM.onHideLoading();
                }).catch((error) => {     
                    creditNoteDetailVM.onHideLoading();
                })
            }else{
                messageBarVM.showWarining(data.data??'')
                creditNoteDetailVM.onHideLoading();
            }
        }).catch((error) => {                
            creditNoteDetailVM.onHideLoading();
            messageBarVM.showError(error.message)
        });
    }, [creditNoteDetailState.creditNoteDetails, creditNoteDetailState.currentCreditNoteHdr, creditNoteDetailState.selectedcreditNoteDetailRows, creditNoteDetailVM, deleteRebateValidate, messageBarVM]);

    
    const handleDeleteClick = useCallback(() => {
        setIsShowDelete(true);
    }, []);    

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    const isApplyDisable = () => {
        if (creditNoteDetailState.currentCreditNoteHdr.hdrState === "NIL") return false;

        return true;
    }
    const isDeleteDisable = () => {
        if (creditNoteDetailState.selectedcreditNoteDetailRows.length > 0 
            && creditNoteDetailState.currentCreditNoteHdr.hdrState === "NIL"
            && creditNoteDetailState.creditNoteDetails.every(dtl => dtl.adjType === "Normal")) return false;

        return true;
    }

    return <Sidebarheader style={{width: '100%'}}>
        <HeaderWithBackButton callback={creditNoteDetailVM.onCloseClick}>
        <Breadcrumb>
        <HPHBreadcrumb breadcrumbData={[{title: ChargeConstant.Charge.CHARGE},{ title: CREDIT_NOTE_CONSTANT.CREDIT_NOTE }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
        </Breadcrumb>
        </HeaderWithBackButton>
        <StyledAction className="tm-animated-wrapper">
            {(allowDelete) && <HPHButton disabled={!isRead || (isRead && isDeleteDisable())} label={WorkspaceConstant.Common.BUTTON_DELETE} size={'Small'} theme={'Secondary'} onClick={handleDeleteClick} />}
            {(allowUpdate) && <HPHButton disabled={!isRead || (isRead && isApplyDisable())} label={WorkspaceConstant.Common.BUTTON_UPDATEALL} size={'Small'} theme={'Primary'} onClick={handleApply} />}
            
            {isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${CreditNoteConstant.Title.CREDIT_NOTE_DETAIL}`} 
                contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                visible={isShowDelete}
                onCancelClick={handleCancel}
                onDeleteClick={handleDelete}
            />}
        </StyledAction>
    </Sidebarheader>
}

export default memo(CreditNoteDetailTitleBar);