import { CreditNoteHeaderEntity, EMPTY_CREDIT_NOTE_HEADER_ENTITY } from "domain/entity/CreditNote/CreditNoteHeaderEntity";
import { CreditNoteHeaderSearchCriteria, EMPTY_CREDIT_NOTE_HEADER_SEARCH_CRITERIA } from "domain/entity/CreditNote/CreditNoteHeaderSearchCriteria";
import { CreditNoteHeaderEnabledSearchCriteria, DEFAULT_ENABLED_CREDIT_NOTE_HEADER_SEARCH_CRITERIA } from "presentation/constant/CreditNote/CreditNoteHeaderEnabledSearchCriteria";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface CreditNoteHeaderDropdownOptions {

    hdrStatusDropdownOptions: DropdownProps[],
    billToCompDropdownOptions: DropdownProps[],
    createdByDropdownOptions: DropdownProps[],
    
}

export interface CreditNoteHeaderViewChangeState extends BaseViewChangeSate {
    
}

export interface CreditNoteHeaderMaintenanceModel {
    
    currentCreditNoteHdr: CreditNoteHeaderEntity,
    isLoading:boolean,
    isShowCriteriaPanel: boolean,
    searchCriteria: CreditNoteHeaderSearchCriteria,    
    dynamicOptions: CreditNoteHeaderDropdownOptions,
    creditNoteHeaders: CreditNoteHeaderEntity[],
    selectedCreditNoteHeaderRows: CreditNoteHeaderEntity[],
    allFormState?: {[x:string]:string},
    enabledSearchCriteria: CreditNoteHeaderEnabledSearchCriteria,
    isShowDetail: boolean,
    isBackFromDetail: boolean,
    isAllowAutoSearch: boolean,
    creditNoteHeaderState: CreditNoteHeaderViewChangeState,
}

export const EMPTY_CREDIT_NOTE_HEADER_MAINTENANCE_MODEL: CreditNoteHeaderMaintenanceModel = {
    isLoading: false,
    isShowCriteriaPanel: false,
    isBackFromDetail: false,
    isAllowAutoSearch: true,
    searchCriteria: { ...EMPTY_CREDIT_NOTE_HEADER_SEARCH_CRITERIA },
    dynamicOptions: {
        hdrStatusDropdownOptions: [],
        billToCompDropdownOptions: [],
        createdByDropdownOptions: [],
    },
    creditNoteHeaders: [],
    selectedCreditNoteHeaderRows: [],
    allFormState: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_CREDIT_NOTE_HEADER_SEARCH_CRITERIA },
    isShowDetail: false,
    currentCreditNoteHdr: {...EMPTY_CREDIT_NOTE_HEADER_ENTITY},
    creditNoteHeaderState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        }
    }
}