import { VATPaymentReferenceDatePolicy } from "constants/charge/VATPaymentReferenceDatePolicy";
import { CoaEntity } from "domain/entity/Company/CoaEntity";
import { CustomerEntity } from "domain/entity/Company/CustomerEntity";
import { CustomerSearchCriteria } from "domain/entity/Company/CustomerSearchCriteria";
import { CreditNoteDetailEntity, EMPTY_CREDIT_NOTE_DETAIL_ENTITY } from "domain/entity/CreditNote/CreditNoteDetailEntity";
import { CreditNoteHeaderEntity, EMPTY_CREDIT_NOTE_HEADER_ENTITY } from "domain/entity/CreditNote/CreditNoteHeaderEntity";
import { CreditNoteInvoiceDtlRecordEntity } from "domain/entity/CreditNote/CreditNoteInvoiceDtlRecordEntity";
import { CreditNoteInvoiceRecordEntity } from "domain/entity/CreditNote/CreditNoteInvoiceRecordEntity";
import { EMPTY_INVOCIE_RECORD_SEARCH_CRITERIA, InvoiceRecordSearchCriteria } from "domain/entity/CreditNote/InvoiceRecordSearchCriteria";
import { DeleteCreditNoteDetail } from "domain/entity/CreditNote/NewCreditNoteData";
import { TariffCoaMappingEntity } from "domain/entity/Document/TariffCoaMappingEntity";
import { CoaRepository } from "domain/repository/Company/CoaRepo";
import { CustomerRepository } from "domain/repository/Company/CustomerRepo";
import { CreditNoteDetailRepository } from "domain/repository/CreditNote/CreditNoteDetailRepo";
import { CreditNoteHeaderRepository } from "domain/repository/CreditNote/CreditNoteHeaderRepo";
import { TariffCoaMappingRepository } from "domain/repository/Document/TariffCoaMappingRepo";
import { ExchangeRateRepository } from "domain/repository/ExchangeRate/ExchangeRateRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { RequisitionFormRepository } from "domain/repository/RequisitionForm/RequisitionFormRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffProposalRepository } from "domain/repository/TariffCode/TariffProposalRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import _ from "lodash";
import { uomDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { ParameterCodeValue, ParameterDetailCodeValue } from "presentation/constant/Parameter/ParameterCodeValue";
import { CreditNoteDetailModel } from "presentation/model/CreditNote/CreditNoteDetailModel";
import { CreditNoteHeaderMaintenanceModel } from "presentation/model/CreditNote/CreditNoteHeaderMaintenanceModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface CreditNoteDetailVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CreditNoteDetailModel>> | ((value: SetStateAction<CreditNoteDetailModel>) => void),
    ],
    hdrDispatch: [
        Dispatch<SetStateAction<CreditNoteHeaderMaintenanceModel>> | ((value: SetStateAction<CreditNoteHeaderMaintenanceModel>) => void),
    ],
    creditNoteHeaderRepo: CreditNoteHeaderRepository,
    creditNoteDetailRepo: CreditNoteDetailRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    customerRepo: CustomerRepository,
    exchangeRateRepo: ExchangeRateRepository,
    requisitionFormRepo: RequisitionFormRepository,
    tariffProposalRepo: TariffProposalRepository,
    masterDataRepo: MasterDataRepository,
    parameterDetailRepo: ParameterDetailRepository,
    tariffCoaMappingRepo:  TariffCoaMappingRepository,
    coaRepo: CoaRepository,
}

export const CreditNoteDetailVM = ({hdrDispatch,dispatch,creditNoteDetailRepo,creditNoteHeaderRepo,standardTariffCodeRepo,
    customerRepo,exchangeRateRepo,requisitionFormRepo,tariffProposalRepo,masterDataRepo,parameterDetailRepo,tariffCoaMappingRepo,
    coaRepo}:CreditNoteDetailVMProps) => {
    const [creditNoteDetailDispatch] = dispatch;
    const [creditNoteHeaderDispatch] = hdrDispatch;

    const loadDropdownOption = async() => {

        await masterDataRepo.getMasterDataByKey('TerminalEntity').then(
            operatingTmls => {
                const operatingTmlDropdownOptions = operatingTmls?.map((operatingTml)=>({
                    dropdownLabel: operatingTml.code,
                    tagLabel: operatingTml.code,
                    value: operatingTml.code,
                })) ?? []

                creditNoteDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: operatingTmlDropdownOptions,
                    }
                }))
            }           
        )

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {
                
                let newTariffs = _.orderBy(tariffs, ["tariffType","tariffCode"]);                
                let tariffTypeDropdownOptions:DropdownProps[] = [];
                let tariffCodeDropdownOptions:{[key:string]: DropdownProps[]} = {};
                let allTariffCodeeDropdownOptions:DropdownProps[] = [];

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t => 
                        t.value === tariff.tariffType);
                    if(!isTariffTypeExisted){
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t => 
                        t.value === tariff.tariffCode);

                    if(!isTariffCodeExisted){
                        allTariffCodeeDropdownOptions.push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }

                    if(tariff.tariffCode){
                        if(!tariffCodeDropdownOptions[tariff.tariffType]){
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })            

                creditNoteDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        taxTariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                        taxTariffCodeDropdownOptions: tariffCodeDropdownOptions,
                        allTariffCodeeDropdownOptions: allTariffCodeeDropdownOptions,
                    }
                }))
            }
        )

        await customerRepo.getAllCustomers().then(
            customers => {
                let customerCodeDropdownOptions = customers?.map((customer) => ({
                    dropdownLabel: customer.customerCode,
                    tagLabel: customer.customerCode,
                    value: customer.customerCode,
                })) ?? []

                creditNoteDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        customerCodeDropdownOptions: customerCodeDropdownOptions,
                    }
                }))
            }
        )

        await exchangeRateRepo.getAllCurrencies().then(
            currencies => {
                let currencyDropdownOptions = currencies?.map((currency) => ({
                    dropdownLabel: currency.currencyCode,
                    tagLabel: currency.currencyCode,
                    value: currency.currencyCode,
                })) ?? []

                creditNoteDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        currencyCodeDropdownOptions: currencyDropdownOptions,
                    }
                }))
            }
        )

        await requisitionFormRepo.getRequisitionFormForComboBox("isNull").then(
            requisitionForms => {
                let requisitionFormDropdownOptions = requisitionForms?.map((requisitionForm) => ({
                    dropdownLabel: requisitionForm.reqFormNo,
                    tagLabel: requisitionForm.reqFormNo,
                    value: requisitionForm.reqFormNo,
                })) ?? []

                creditNoteDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        reqNoDropdownOptions: requisitionFormDropdownOptions,
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey('ChargeIndEntity').then(
            chargeInds => {
                if(chargeInds){
                    let newChargeInds = _.orderBy(chargeInds, ["code"]);
                    const chargeIndDropdownOptions = newChargeInds?.map((chargeInd)=>({
                        dropdownLabel: chargeInd.code,
                        tagLabel: chargeInd.code,
                        value: chargeInd.code,
                    })) ?? []
    
                    creditNoteDetailDispatch(prevState => ({
                        ...prevState,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            chargeIndDropdownOptions: chargeIndDropdownOptions,
                        }
                    }))
                }
            }           
        )

        await masterDataRepo.getMasterDataByKey('InterCompanyEntity').then(
            interCompanys => {
                const interCompanyCodeDropdownOptions = interCompanys?.map((interCompany)=>({
                    dropdownLabel: interCompany.code,
                    tagLabel: interCompany.code,
                    value: interCompany.code,
                })) ?? []

                creditNoteDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        interCompanyCodeDropdownOptions: interCompanyCodeDropdownOptions,
                        taxInterCompanyCodeDropdownOptions: interCompanyCodeDropdownOptions,
                    }
                }))
            }           
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.TAX_CODE).then(
            parDtls => {
                if(parDtls){
                    let newParDtls = _.orderBy(parDtls, ["parameterDtlCode"]);
                    const taxCodeDropdownOptions = newParDtls?.map((parDtl) => ({
                        dropdownLabel: parDtl.parameterDtlCode,
                        tagLabel: parDtl.parameterDtlCode,
                        value: parDtl.parameterDtlCode,
                    })) ?? []
    
                    creditNoteDetailDispatch(prevState => ({
                        ...prevState,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            taxCodeDropdownOptions: taxCodeDropdownOptions
                        }
                    }))
                }
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.ARTICLE_STMT_CODE).then(
            parDtls => {
                if(parDtls){
                    let newParDtls = _.orderBy(parDtls, ["parameterDtlCode"]);
                    const vatArticleStatementCodes = newParDtls?.map((parDtl) => ({
                        dropdownLabel: parDtl.parameterDtlCode,
                        tagLabel: parDtl.parameterDtlCode,
                        value: parDtl.parameterDtlCode,
                    })) ?? []
    
                    creditNoteDetailDispatch(prevState => ({
                        ...prevState,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            articleStatementCodeDropdownOptions: vatArticleStatementCodes
                        }
                    }))
                }
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.ACCOUNT_TERMINAL_REASON_CODE).then(
            parDtls => {
                if(parDtls){
                    let newParDtls = _.orderBy(parDtls, ["parameterDtlCode"]);
                    const reasonCodeDropdownOptions = newParDtls?.map((parDtl) => ({
                        dropdownLabel: parDtl.parameterDtlCode,
                        tagLabel: parDtl.parameterDtlCode,
                        value: parDtl.parameterDtlCode,
                    })) ?? []
    
                    creditNoteDetailDispatch(prevState => ({
                        ...prevState,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            reasonCodeDropdownOptions: reasonCodeDropdownOptions
                        }
                    }))
                }
            }
        )

    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        let tarCode: any;
        let vatTarCode: any;
      if (_.isArray(val)) {
        val = _.uniq(val?.map((item: any) => item.value || item.key));
      }else if (_.isString(val)) {
        if(fieldKey === 'invoiceNo'){
          val = val.toUpperCase().replace(/\s+/g, '');
        }else if(fieldKey === 'creditChargingQty'){
            val = val.replace(/[^0-9]/g, '');
        }else if(fieldKey === 'creditAmount'){
            val.replace(/[^-\d.]|(\..*?)\./g, '$1');;
        }
      }
      if("vatPercent" === fieldKey){
          creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                creditNoteDetailState: {
                    ...prevState.creditNoteDetailState,
                    currentCreditNoteDtl: {
                        ...prevState.creditNoteDetailState.currentCreditNoteDtl,
                        [fieldKey]: val,                    
                        adjPercentage: val                    
                    },
                    allFormState: {
                        ...prevState.creditNoteDetailState.allFormState,
                        [fieldKey]: '' 
                    },
                }            
            }
          });

      }else{
          creditNoteDetailDispatch(prevState => {
            if(fieldKey ==='tariffType'){
                tarCode = prevState.dynamicOptions.tariffCodeDropdownOptions[val]?prevState.dynamicOptions.tariffCodeDropdownOptions[val][0].value:"";
            }
            if(fieldKey ==='tariffCode'){
                tarCode = val;
            }
            if(fieldKey ==='vatTariffType'){
                vatTarCode = prevState.dynamicOptions.taxTariffCodeDropdownOptions[val]?prevState.dynamicOptions.taxTariffCodeDropdownOptions[val][0].value:"";
            }
            if(fieldKey ==='vatTariffCode'){
                vatTarCode = val;
            }
            return {
                ...prevState,
                creditNoteDetailState: {
                    ...prevState.creditNoteDetailState,
                    currentCreditNoteDtl: {
                        ...prevState.creditNoteDetailState.currentCreditNoteDtl,
                        [fieldKey]: val,
                        tariffCode:tarCode && tarCode!==null ? tarCode : prevState.creditNoteDetailState.currentCreditNoteDtl.tariffCode,
                        vatTariffCode:vatTarCode && vatTarCode!==null ? vatTarCode : prevState.creditNoteDetailState.currentCreditNoteDtl.vatTariffCode                            
                    },
                    allFormState: {
                        ...prevState.creditNoteDetailState.allFormState,
                        [fieldKey]: '' 
                    },
                }            
            }
          });
      }
    }

    const onTextAreaChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        return creditNoteDetailDispatch(prevState => {
          return {
              ...prevState,
              creditNoteDetailState: {
                  ...prevState.creditNoteDetailState,
                  currentCreditNoteDtl: {
                      ...prevState.creditNoteDetailState.currentCreditNoteDtl,
                      [fieldKey]: val,
                  }
              }            
          }
        });
      }

      const onCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        let coVal : string = "";
        let vslVal : string = "";
        let voyVal : string = "";
        if(inputData?.co){
            coVal = inputData?.co.toUpperCase().replace(/\s+/g, '');
        }
        if(inputData?.vsl){
            vslVal = inputData?.vsl.toUpperCase().replace(/\s+/g, '');
        }
        if(inputData?.voy){
            voyVal = inputData?.voy.toUpperCase();
        }
        creditNoteDetailDispatch(prevState => ({
            ...prevState,
            creditNoteDetailState:{
                ...prevState.creditNoteDetailState,
                editingHeader:{
                    ...prevState.creditNoteDetailState.editingHeader,
                    [fieldName.co]: coVal,
                    [fieldName.vsl]: vslVal,
                    [fieldName.voy]: voyVal,
                }
            }
        }))
    }

    const onRefreshReqNo = async (currencyCode: string) => {
        await requisitionFormRepo.getRequisitionFormForComboBox(currencyCode).then(
            requisitionForms => {
                let requisitionFormDropdownOptions = requisitionForms?.map((requisitionForm) => ({
                    dropdownLabel: requisitionForm.reqFormNo,
                    tagLabel: requisitionForm.reqFormNo,
                    value: requisitionForm.reqFormNo,
                })) ?? []

                creditNoteDetailDispatch(prevState => ({
                    ...prevState,
                    
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        reqNoDropdownOptions: requisitionFormDropdownOptions,
                    }
                }))
            }
        )
    }
    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
      if (_.isArray(val)) {
        val = _.uniq(val?.map((item: any) => item.value || item.key));
      }else if (_.isString(val)) {
          if(fieldKey === 'vesselName' || fieldKey === 'slVesselCode' || fieldKey === 'slIbVoyageCode'){
            val = val.toUpperCase();
          }else if(fieldKey === 'slObVoyageCode' || fieldKey === 'poNo'){
              val = val.toUpperCase().replace(/\s+/g, '');
          }
      }
      if(fieldKey === 'currencyCode'){
        await requisitionFormRepo.getRequisitionFormForComboBox(val).then(
            requisitionForms => {
                let requisitionFormDropdownOptions = requisitionForms?.map((requisitionForm) => ({
                    dropdownLabel: requisitionForm.reqFormNo,
                    tagLabel: requisitionForm.reqFormNo,
                    value: requisitionForm.reqFormNo,
                })) ?? []

                creditNoteDetailDispatch(prevState => {
                    return {
                            ...prevState,
                        creditNoteDetailState:{
                            ...prevState.creditNoteDetailState,
                            editingHeader:{
                                ...prevState.creditNoteDetailState.editingHeader,
                                reqNo: '',
                            }
                        },
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            reqNoDropdownOptions: requisitionFormDropdownOptions,
                        },
                    }
                })
            }
        )
      }
      return creditNoteDetailDispatch(prevState => {
        return {
            ...prevState,
                // currentCreditNoteHdr: {
                //     ...prevState.currentCreditNoteHdr,
                //     [fieldKey]: val
                // },
                creditNoteDetailState: {
                    ...prevState.creditNoteDetailState,
                    editingHeader: {
                        ...prevState.creditNoteDetailState.editingHeader,
                        [fieldKey]: val
                    },
                    allFormState: {
                        ...prevState.creditNoteDetailState.allFormState,
                        [fieldKey]: '' 
                    },
                },
            }          
        })
    }

    const onAdd = () => {
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                creditNoteDetailState:{
                    ...prevState.creditNoteDetailState,
                    isTabularDataActive: false,
                    isAdd: true,
                    isRead: false,
                    isEditable: false,
                    isSaveClicked: false,
                    currentCreditNoteDtl: {...EMPTY_CREDIT_NOTE_DETAIL_ENTITY},
                    lastEditRowId: '',
                    isSliderOpen: true,
                    isShowHeaderInfo: true,
                }
            }
        });       
    }

    const onCloseClick = () => {
        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail:true,
                isAllowAutoSearch:true,
                selectedCreditNoteHeaderRows:[],
                currentCreditNoteHdr: {...EMPTY_CREDIT_NOTE_HEADER_ENTITY}
            }
        }); 
    }


    const updateSelectedCharges = (rows:any[]) => {
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                selectedcreditNoteDetailRows: rows,
                creditNoteDetailState:{
                    ...prevState.creditNoteDetailState,
                    isShowDetailInfo: rows.length===1?true:false,
                    currentCreditNoteDtl: rows.length===1?rows[0]:{...EMPTY_CREDIT_NOTE_DETAIL_ENTITY}
                }
            }
        })
    }

    const updateSelectedInvoiceDtlRecordCharges = async (rows:any[]) => {
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                selectInvoiceDtlRecords: rows,
            }
        })
    }
    const updateSelectedInvoiceRecordCharges = async (rows:any[]) => {
        if(rows && rows.length === 1){
            // await creditNoteDetailRepo.getDocDtl(rows[0].id).then((data) =>{
                creditNoteDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        selectInvoiceRecords:rows,
                        creditNoteDetailState: {
                            ...prevState.creditNoteDetailState,
                            isShowTariffItemPanel:true,
                        },
                        invoiceDtlRecordList: [],
                    }
                })
            // })
        }else{
            creditNoteDetailDispatch(prevState => {
                return {
                    ...prevState,
                    selectInvoiceRecords: [],
                    creditNoteDetailState: {
                        ...prevState.creditNoteDetailState,
                        isShowTariffItemPanel:false,
                    },
                    invoiceDtlRecordList:[]
                }
            })
        }
    }

    const onPageInit = (currentCreditNoteHdr: CreditNoteHeaderEntity | null,billToCompDropdownOptions: DropdownProps[]) => {
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                currentCreditNoteHdr: currentCreditNoteHdr ?? {...EMPTY_CREDIT_NOTE_HEADER_ENTITY},
                dynamicOptions:{
                    ...prevState.dynamicOptions,
                    billToCompDropdownOptions: billToCompDropdownOptions
                },
                creditNoteDetailState: {
                    ...prevState.creditNoteDetailState,
                    isAdd: currentCreditNoteHdr?.id === null,
                    isRead: !!(currentCreditNoteHdr?.id),
                    isEditable: !!(currentCreditNoteHdr?.id),
                    currentCreditNoteDtl: {...EMPTY_CREDIT_NOTE_DETAIL_ENTITY},
                }
            }
        })
    }

    const onShowLoading = () => {
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const onSearch = async (creditNoteHdr: CreditNoteHeaderEntity ) => {
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                selectedcreditNoteDetailRows: [],
                creditNoteDetails:[]
            }
        })
        if(creditNoteHdr.id){
            await creditNoteDetailRepo.getCreditNoteDetailsByHdrId(creditNoteHdr.id).then((data) =>{
                creditNoteDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        creditNoteDetails: data,
                        currentCreditNoteHdr: creditNoteHdr
                    }
                })
            })

        }

    }
    const onGetUom = async (companyCode:string | null, tariffType: string, tariffCode: string,
        fromDate: Date,toDate: Date, operatingTml: string | null ) => {
        return await tariffProposalRepo.searchDefaultQtyUomByTariff({
            companyCode: companyCode,
            tariffType: tariffType,
            tariffCode: tariffCode,
            fromDate: fromDate,
            toDate:toDate,
            operatingTml:operatingTml,
        }).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                creditNoteDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"getUOMFail": data.toString()}
                    };
                });
                return {"getUOMFail": data.toString()};
            } else {
                const uom = data;
                if(uomDropdownOption.find(u => u.value === uom)) {
                    creditNoteDetailDispatch(prevState => ({
                        ...prevState,
                        creditNoteDetailState: {
                            ...prevState.creditNoteDetailState,
                            currentCreditNoteDtl: {
                                ...prevState.creditNoteDetailState.currentCreditNoteDtl,
                                individualChargeQtyUom: uom,
                            }
                        }
                    }))
                    return {"getUOMSuccess":"successful"};
                }
            }
        }).catch(error => {
            creditNoteDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"getUOMFail": error.message}                        
                }
            });
            return {"getUOMFail": error.message};
        });
    }

    const onAdjTypeItemChanged = (adjType: string | null) => {
        if(adjType){
            parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.CREDIT_NOTE_NATURE).then(
                parDtls => {
                    const creditNoteNatureDropdownOptions: DropdownProps[] = [];
                    parDtls.forEach((value, index) => {
                        if(adjType === value.parameterDtlCode && "Y" === value.activeInd){
                            creditNoteNatureDropdownOptions.push({
                                dropdownLabel: value.parameterDtlCode+"-"+value.parameterDtlDesc,
                                tagLabel: value.parameterDtlCode+"-"+value.parameterDtlDesc,
                                value: value.parameterDtlCode+"-"+value.parameterDtlDesc,
                            })
                        }
                    });
    
                    creditNoteDetailDispatch(prevState => ({
                        ...prevState,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            creditNoteNatureDropdownOptions: creditNoteNatureDropdownOptions,
                        }
                    }))
                }
            )
        }else{
            creditNoteDetailDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    creditNoteNatureDropdownOptions: [],
                }
            }))
        }
    }

    const onVatCoaInfoUpdate = async (countryCode:string,newDtl:CreditNoteDetailEntity/*tariffType: string | null, tariffCode: string  | null*/) => {
        // let newDtl = {...EMPTY_CREDIT_NOTE_DETAIL_ENTITY};
        if(newDtl.vatTariffType && newDtl.vatTariffCode){
            let isUpdate = true;
            let noRecordFound = false;
            const tarCoa = await getActiveTarffCoa(newDtl.vatTariffType,newDtl.vatTariffCode);
            const coas = await getCoa(tarCoa,countryCode??"");
            if (isUpdate) {
                if(tarCoa){
                    if(coas && coas.length >= 1){
                        // vatCoaInfoUpdate(coas[0]);
                        newDtl.vatRevenueACCode = coas[0].revenueAccountCode;
                        newDtl.vatCostCenterCode = coas[0].costCenterCode;
                        newDtl.vatProductServiceCode = coas[0].productServiceCode;
                        newDtl.vatSalesChannelCode = coas[0].salesChannelCode;
                        newDtl.vatProjectCode = coas[0].projectCode;
                        newDtl.vatSpareCode = coas[0].spareCode;
                        const interCompanyCodes: string[] = [];
                        coas.forEach((coa) => {
                            if(coa.intercompanyCode && !interCompanyCodes.includes(coa.intercompanyCode)){
                                interCompanyCodes.push(coa.intercompanyCode);
                            }
                        });
                        if(interCompanyCodes && interCompanyCodes.length === 1){
                            newDtl.vatIntercompanyCode = interCompanyCodes[0];  
                        }
                    }else{
                        noRecordFound = true;
                    }
                }else{
                    noRecordFound = true;
                }
                if (noRecordFound) {
                    newDtl.vatRevenueACCode = "";
                    newDtl.vatCostCenterCode = "";
                    newDtl.vatProductServiceCode= "";
                    newDtl.vatSalesChannelCode= "";
                    newDtl.vatProjectCode = "";
                    newDtl.vatSpareCode = "";
                }
            }
        }
        return newDtl;
    }
    const onCoaInfoUpdate = async (countryCode:string,newDtl:CreditNoteDetailEntity/*tariffType: string | null, tariffCode: string  | null*/) => {
        // let newDtl = {...EMPTY_CREDIT_NOTE_DETAIL_ENTITY};
        if(newDtl.tariffType && newDtl.tariffCode){
            let isUpdate = true;
            let noRecordFound = false;
            const tarCoa = await getActiveTarffCoa(newDtl.tariffType,newDtl.tariffCode);
            const coas = await getCoa(tarCoa,countryCode??"");
            if (isUpdate) {
                if(tarCoa){
                    if(coas && coas.length >= 1){
                        // vatCoaInfoUpdate(coas[0]);
                        newDtl.revenueAccountCode = coas[0].revenueAccountCode;
                        newDtl.costCenterCode = coas[0].costCenterCode;
                        newDtl.productServiceCode = coas[0].productServiceCode;
                        newDtl.salesChannelCode = coas[0].salesChannelCode;
                        newDtl.projectCode = coas[0].projectCode;
                        newDtl.spareCode = coas[0].spareCode;
                        const interCompanyCodes: string[] = [];
                        coas.forEach((coa) => {
                            if(coa.intercompanyCode && !interCompanyCodes.includes(coa.intercompanyCode)){
                                interCompanyCodes.push(coa.intercompanyCode);
                            }
                        });
                        if(interCompanyCodes && interCompanyCodes.length === 1){
                            newDtl.intercompanyCode = interCompanyCodes[0];  
                        }
                    }else{
                        noRecordFound = true;
                    }
                }else{
                    noRecordFound = true;
                }
                if (noRecordFound) {
                    newDtl.revenueAccountCode = "";
                    newDtl.costCenterCode = "";
                    newDtl.productServiceCode= "";
                    newDtl.salesChannelCode= "";
                    newDtl.projectCode = "";
                    newDtl.spareCode = "";
                }
            }
        }
        return newDtl;
    }

    const onTariffCodeItemChanged = async (isVat:boolean,countryCode:string,newDtl:CreditNoteDetailEntity/*tariffType: string | null, tariffCode: string  | null*/, fieldName: string) => {
        let tariffType,tariffCode;
        if(isVat){
            tariffType = newDtl.vatTariffType;
            tariffCode = newDtl.vatTariffCode;
        }else{
            tariffType = newDtl.tariffType;
            tariffCode = newDtl.tariffCode;
        }
        if(tariffType && tariffCode){
            // let newDtl = dtl;//{...EMPTY_CREDIT_NOTE_DETAIL_ENTITY};
            if(isVat){
                newDtl = await onVatCoaInfoUpdate(countryCode,newDtl/*tariffType,tariffCode*/);
            }else{
                newDtl = await onCoaInfoUpdate(countryCode,newDtl/*tariffType,tariffCode*/);
            }
            await standardTariffCodeRepo.getTariffByTypeAndCode(tariffType,tariffCode).then((data) => {
                if(data){
                    updatecurrentCreditNoteDtl(isVat,newDtl,fieldName, data.tariffCodeDesc); 
                }else{
                    updatecurrentCreditNoteDtl(isVat,newDtl,fieldName, null);  
                }
            }).catch(()=>{
                updatecurrentCreditNoteDtl(isVat,newDtl,fieldName, null);  
            })
        }else{
            updatecurrentCreditNoteDtl(isVat,{...EMPTY_CREDIT_NOTE_DETAIL_ENTITY},fieldName, null);  
        }        
    }

    const updatecurrentCreditNoteDtl = (isVat:boolean,newDtl:CreditNoteDetailEntity,fieldName: string, newValue: any) => {  
        // if(isVat){
        //     creditNoteDetailDispatch((prevState) => ({  
        //       ...prevState,  
        //       creditNoteDetailState: {  
        //         ...prevState.creditNoteDetailState,  
        //         currentCreditNoteDtl: {  
        //           ...prevState.creditNoteDetailState.currentCreditNoteDtl,  
        //             [fieldName]: newValue, 
        //             // vatRevenueACCode: newDtl.vatRevenueACCode,
        //             // vatCostCenterCode : newDtl.vatCostCenterCode,
        //             // vatProductServiceCode : newDtl.vatProductServiceCode,
        //             // vatSalesChannelCode : newDtl.vatSalesChannelCode,
        //             // vatProjectCode : newDtl.vatProjectCode,
        //             // vatSpareCode : newDtl.vatSpareCode, 
        //             // vatIntercompanyCode : newDtl.vatIntercompanyCode
        //         },  
        //       },  
        //     }));  
        // }else{
            creditNoteDetailDispatch((prevState) => ({  
              ...prevState,  
              creditNoteDetailState: {  
                ...prevState.creditNoteDetailState,  
                currentCreditNoteDtl: {  
                  ...prevState.creditNoteDetailState.currentCreditNoteDtl,  
                    [fieldName]: newValue, 
                    // revenueAccountCode: newDtl.revenueAccountCode,
                    // costCenterCode : newDtl.costCenterCode,
                    // productServiceCode : newDtl.productServiceCode,
                    // salesChannelCode : newDtl.salesChannelCode,
                    // projectCode : newDtl.projectCode,
                    // spareCode : newDtl.spareCode, 
                    // intercompanyCode : newDtl.intercompanyCode,
                },  
              },  
            }));  
        // }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        creditNoteDetailDispatch(prevState => ({
            ...prevState,  
          creditNoteDetailState: {  
            ...prevState.creditNoteDetailState,  
            currentCreditNoteDtl: {  
              ...prevState.creditNoteDetailState.currentCreditNoteDtl,  
              [fieldName]: checked,
              rebateInd: checked?"Y":"N"  
            },  
        },  
        }));
    }

    const onDetailAddClick = () =>{
        creditNoteDetailDispatch(prevState => ({
            ...prevState,  
          creditNoteDetailState: {  
            ...prevState.creditNoteDetailState, 
            isAdd: true,
            isRead: false,
            isEditable: true,
            isSaveClicked: false,
            isDetailAddClick: true,
            isShowDetailInfo: true,
            isByManual: true,
            currentCreditNoteDtl: {...EMPTY_CREDIT_NOTE_DETAIL_ENTITY} 
        },  
        }));
    }

    const onCancelClick = () =>{
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,  
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    isShowDetailInfo:false,
                    isAdd:false,
                    isByManual: false,
                    isByInvoice: false,
                    currentCreditNoteDtl: {...EMPTY_CREDIT_NOTE_DETAIL_ENTITY} 
                },  
            }
        });
    }

    const onRowClick = (creditNoteDtl: CreditNoteDetailEntity) => {

        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    isShowDetailInfo: true,
                    isAdd:false,
                    currentCreditNoteDtl: creditNoteDtl
                }, 
            }
        }); 

    }

    const onTitleSaveClick = async(currentCreditNoteHdr: CreditNoteHeaderEntity,creditNoteDtls: CreditNoteDetailEntity[]) => {
        let totalAmount = 0;
        creditNoteDtls.forEach((dtl) =>{
            totalAmount += Number(dtl.creditAmount)??0;
            if(dtl.vatPercent){
                totalAmount += totalAmount*dtl.vatPercent*0.01;
            }
            if(dtl.creditChargingQty){
                dtl.isInputAmountInd = "Y";
            }
            if(dtl.rebate){
                dtl.rebateInd = "Y";
            }else{
                dtl.rebateInd = "N";
            }
        });
        currentCreditNoteHdr.totalAmount = totalAmount;
        let creditNoteDataList = {
            hdr:currentCreditNoteHdr,
            dtlList:creditNoteDtls
        }
        const hdr = await creditNoteHeaderRepo.saveCreditNoteDataList(creditNoteDataList);
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                currentCreditNoteHdr : hdr,
                selectedcreditNoteDetailRows:[],
                creditNoteDetails:[],
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    editingHeader: hdr,
                    currentCreditNoteDtl:{...EMPTY_CREDIT_NOTE_DETAIL_ENTITY},
                    isAdd: false,
                    isRead: true,
                    isShowDetailInfo: false,
                    isSaveClicked: false,
                }, 
            }
        });

    }

    const onTempSaveClick = async(creditNoteDtl: CreditNoteDetailEntity) => {
        creditNoteDetailDispatch(prevState => {
            let newDtls = prevState.creditNoteDetails.slice();
            let existingDtlIndex = newDtls.findIndex(dtl => {
                if(dtl.id !== 0){
                    return dtl.id === creditNoteDtl.id
                }else{
                    return dtl.dummyKey === creditNoteDtl.dummyKey
                }

            });
            if(existingDtlIndex !== -1){
                Object.assign(newDtls[existingDtlIndex], creditNoteDtl);
            } else {
                newDtls.push(creditNoteDtl);
            }
            return {
                ...prevState,
                selectedcreditNoteDetailRows:[],
                creditNoteDetails: newDtls,
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    currentCreditNoteDtl:{...EMPTY_CREDIT_NOTE_DETAIL_ENTITY},
                    isShowDetailInfo: false,
                    isSaveClicked: false,
                    isDetailAddClick: false,
                    isByManual: false,
                    isByInvoice: false,
                }, 
            }
        });
    }

    const onSaveClick = async(currentCreditNoteHdr: CreditNoteHeaderEntity,creditNoteDtl: CreditNoteDetailEntity,isAdd:boolean) => {
        let totalAmount = 0;
        totalAmount = Number(creditNoteDtl.creditAmount)??0;
        if(creditNoteDtl.vatPercent && creditNoteDtl.vatPercent > 0){
            totalAmount += totalAmount*creditNoteDtl.vatPercent*0.01;
        }
        if(creditNoteDtl.creditChargingQty){
            creditNoteDtl.isInputAmountInd = "Y";
        }
        if(creditNoteDtl.rebate){
            creditNoteDtl.rebateInd = "Y";
        }else{
            creditNoteDtl.rebateInd = "N";
        }
        currentCreditNoteHdr.totalAmount = totalAmount;
        let creditNoteData = {
            hdr:currentCreditNoteHdr,
            dtl:creditNoteDtl
        }
        if(isAdd){
            const hdr = await creditNoteHeaderRepo.saveNewCreditNoteData(creditNoteData);
                
            creditNoteDetailDispatch(prevState => {
                return {
                    ...prevState,
                    currentCreditNoteHdr : hdr,
                    selectedcreditNoteDetailRows:[],
                    creditNoteDetails:[],
                    creditNoteDetailState: {  
                        ...prevState.creditNoteDetailState, 
                        editingHeader: hdr,
                        currentCreditNoteDtl:{...EMPTY_CREDIT_NOTE_DETAIL_ENTITY},
                        isAdd: false,
                        // isEditable: true,
                        // isRead: true,
                        isShowDetailInfo: false,
                        isSaveClicked: false
                    }, 
                }
            });
            return hdr;
        }else{
            const hdr = await creditNoteHeaderRepo.updateCreditNoteData(creditNoteData);
            creditNoteDetailDispatch(prevState => {
                return {
                    ...prevState,
                    currentCreditNoteHdr : hdr,
                    selectedcreditNoteDetailRows:[],
                    creditNoteDetails:[],
                    creditNoteDetailState: {  
                        ...prevState.creditNoteDetailState, 
                        currentCreditNoteDtl:{...EMPTY_CREDIT_NOTE_DETAIL_ENTITY},
                        isAdd: false,
                        // isEditable: true,
                        // isRead: true,
                        isShowDetailInfo: false,
                        isSaveClicked: false
                    }, 
                }
            });
            return hdr;
        }
    }

    const getTaxCode = async() => {
        return await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.TAX_CODE);
    }

    const getCustomersByCompany = async(effectiveDate: Date | null,customerCode: string,companyCode: string) => {
        let customerSearchCriteria : CustomerSearchCriteria= {
            effectiveDate:  effectiveDate,
            customerCode:  customerCode,
            companyCode: companyCode,
            filterCustomer: null,
            retrieveActiveCustomer: null,
            companyId: null,
        }
        return await customerRepo.getCustomersByCompany(customerSearchCriteria);
    }

    const getActiveTarffCoa = async(tariffType: string,tariffCode: string) => {
        return await tariffCoaMappingRepo.getTariffCoaByTarTypeCode(tariffType, tariffCode);
    }

    const getCoa = async(tarCoa: TariffCoaMappingEntity,countryCode:string) => {
        if(!tarCoa){
            return null;
        }
        return await coaRepo.searchCoas({  
            revenueAccountCode: tarCoa.revenueAccountCode,  
            costCenterCode: tarCoa.costCenterCode,  
            productServiceCode: tarCoa.productServiceCode,  
            salesChannelCode: tarCoa.salesChannelCode, 
            projectCode: tarCoa.projectCode,  
            spareCode: tarCoa.spareCode,  
            countryCode: countryCode,  
        });
    }
    
    const setInterCompanyCode = (interCompanyCode: string) => {
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    currentCreditNoteDtl: {  
                        ...prevState.creditNoteDetailState.currentCreditNoteDtl,  
                        intercompanyCode: interCompanyCode,  
                      }, 
                }, 
            }
        });
    }

    const coaInfoUpdate = (coa: CoaEntity) => {
        if(coa && coa.id){
            creditNoteDetailDispatch(prevState => {
                return {
                    ...prevState,
                    creditNoteDetailState: {  
                        ...prevState.creditNoteDetailState, 
                        currentCreditNoteDtl: {  
                            ...prevState.creditNoteDetailState.currentCreditNoteDtl,  
                            revenueAccountCode: coa.revenueAccountCode,  
                            costCenterCode: coa.costCenterCode,  
                            productServiceCode: coa.productServiceCode,  
                            salesChannelCode: coa.salesChannelCode,  
                            projectCode: coa.projectCode,  
                            spareCode: coa.spareCode,  
                          }, 
                    }, 
                }
            }); 
        }else{
            creditNoteDetailDispatch(prevState => {
                return {
                    ...prevState,
                    creditNoteDetailState: {  
                        ...prevState.creditNoteDetailState, 
                        currentCreditNoteDtl: {  
                            ...prevState.creditNoteDetailState.currentCreditNoteDtl,  
                            revenueAccountCode: null,  
                            costCenterCode: null,  
                            productServiceCode: null,  
                            salesChannelCode: null,  
                            projectCode: null,  
                            spareCode: null,  
                            intercompanyCode: null,
                          }, 
                    }, 
                }
            }); 
        }
    }

    const refreshDefaultTaxCode = async(countryCode:string,companyCode:string,isForTable: boolean,dtl:CreditNoteDetailEntity) => {
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    isDetailAddClick: false,
                }, 
            }
        }); 
        // dtl.countryCode = countryCode;
        // dtl.vatCountryCode = countryCode;
        // dtl.vatCompanyCode = companyCode;
        // dtl.revenueCompanyCode = companyCode;
        // dtl.tariffType = "";
        // dtl.tariffCode = "";
        // dtl.individualChargeQtyUom = "";
        dtl.dummyKey = _.uniqueId();
        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.ACCOUNT_TERMINAL_REASON_CODE).then(
            parDtls => {
                if(parDtls){
                    let newParDtls = _.orderBy(parDtls, ["parameterDtlCode"]);
                    dtl.reasonCode = newParDtls[0].parameterDtlCode;
                }
            });
        const taxCodes = await getTaxCode();
        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.DEFAULT_AR_TAX_CODE).then(
            parDtls => {
                if(parDtls){
                    for(const param of parDtls){
                        if(param.parameterDtlCode === ParameterDetailCodeValue.CREDIT_NOTE_DEF_TAX_CODE){
                            for(const taxCode of taxCodes){
                                if(taxCode.parameterDtlCode === param.parameterDtlDesc){
                                    dtl.taxCode = param.parameterDtlDesc??"";
                                }
                            }
                        }
                    }
                }
        });
        // if(!isForTable) {
        //     if(!dtl.taxCode || !taxCodes || taxCodes.length === 0){
        //         dtl.vatTariffType = "";
        //         dtl.vatTariffCode = "";
        //         dtl.vatArticleStatementCode = "";
        //         dtl.vatArticleStatement = "";
        //         dtl.vatDays = null;
        //         dtl.vatDateType = "";
        //         dtl.vatDtlDesc1 = "";
        //     }
        // }
        let taxCode = dtl.taxCode;
        let tarTypes: string[] = [];
        let tarCodes: string[] = [];
        let tarCodeMaps: {[key:string]:string[]} = {};
        const tariffs = (await standardTariffCodeRepo.getAllStandardTariffCodes());
        let newTariffs = _.orderBy(tariffs, ["tariffType","tariffCode"]);
        newTariffs.forEach(tariff => {
            const isTariffTypeExisted = tarTypes.find(t => 
                t === tariff.tariffType);
                if(!isTariffTypeExisted){
                    tarTypes.push(tariff.tariffType);
                }
            tarCodes = tarCodeMaps[tariff.tariffType];
            let isTariffCodeExisted;
            if(tarCodes){
                isTariffCodeExisted = tarCodes.find(t => 
                    t === tariff.tariffCode);
            }
            if(!tarCodeMaps[tariff.tariffType]){
                tarCodeMaps[tariff.tariffType] = [];
            }
            if(!isTariffCodeExisted){
                tarCodeMaps[tariff.tariffType].push(tariff.tariffCode);
            }
        });
        const vatArticleStatementCodes = (await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.ARTICLE_STMT_CODE));
        for(const param of taxCodes){
            if(taxCode === param.parameterDtlCode){
                const paramDtls = (await parameterDetailRepo.getParameterThirdDtlByParaCodeAndParaDtlCode(ParameterCodeValue.TAX_CODE,param.parameterDtlCode));
                for(const pa of paramDtls){
                    if(ParameterDetailCodeValue.TAX_TAR_TYPE === pa.parameterDtlCode){
                        for(const tarType of tarTypes){
                            if("Y" === pa.activeInd && pa.parameterDtlDesc === tarType){
                                dtl.vatTariffType = pa.parameterDtlDesc;
                                if(tarCodeMaps && tarCodeMaps[dtl.vatTariffType]){
                                    dtl.vatTariffCode = tarCodeMaps[dtl.vatTariffType][0];
                                }
                                break;
                            }else{
                                dtl.vatTariffType = "";
                                dtl.vatTariffCode = "";
                            }
                        }
                    }
                    // if(ParameterDetailCodeValue.TAX_TAR_CODE === pa.parameterDtlCode && dtl.vatTariffType && pa.parameterDtlDesc){
                    //     tarCodes = tarCodeMaps[dtl.vatTariffType];
                    //     if(tarCodes){
                    //         for(const tarCode of tarCodes){
                    //             if("Y" === pa.activeInd && tarCode === pa.parameterDtlDesc){
                    //                 dtl.vatTariffCode = pa.parameterDtlDesc;
                    //                 break;
                    //             }else{
                    //                 dtl.vatTariffCode = "";
                    //             }
                    //         }
                    //     }
                    // }
                    if(ParameterDetailCodeValue.ARTICLE_STATEMENT === pa.parameterDtlCode){
                        for(const para of vatArticleStatementCodes){
                            if("Y" === pa.activeInd && pa.parameterDtlDesc === para.parameterDtlCode){
                                dtl.vatArticleStatementCode = pa.parameterDtlDesc;
                                const paramDtl = (await parameterDetailRepo.getParameterSecondDtlByParaCodeAndParaDtlCode(ParameterCodeValue.ARTICLE_STMT_CODE,dtl.vatArticleStatementCode));
                                dtl.vatArticleStatement = paramDtl.parameterDtlDesc??"";
                                break;
                            }else{
                                dtl.vatArticleStatementCode = "";
                                // dtl.vatArticleStatement = "";
                            }
                        }
                    }
                    if(ParameterDetailCodeValue.TAX_PAYMENT_TERM === pa.parameterDtlCode){
                        if("Y" === pa.activeInd && pa.parameterDtlDesc){
                            dtl.vatDays = Number(pa.parameterDtlDesc);
                            continue;
                        }
                    }
                    if(ParameterDetailCodeValue.TAX_PAYMENT_REFERENCE_DATE === pa.parameterDtlCode){
                        if("Y" === pa.activeInd && pa.parameterDtlDesc){
                            dtl.vatDateType = VATPaymentReferenceDatePolicy().getKeyByText(pa.parameterDtlDesc);
                            continue;
                        }else{
                            dtl.vatDateType = "";
                        }
                    }
                    if(ParameterDetailCodeValue.TAX_DETAIL_DESC === pa.parameterDtlCode){
                        if("Y" === pa.activeInd && pa.parameterDtlDesc){
                            dtl.vatDtlDesc1 = pa.parameterDtlDesc;
                        }
                        continue;
                    }
                }
            }
        }

        let result = "";
        const parameterCode = ParameterCodeValue.TAX_CODE;
        const dtlCode = dtl.taxCode;
        let percentage : number = 0.0;
        if(!dtlCode){
            return;
        }
        const paramDtl = (await parameterDetailRepo.getParameterSecondDtlByParaCodeAndParaDtlCode(parameterCode,dtlCode));
        if(ParameterCodeValue.ARTICLE_STMT_CODE === parameterCode
            && (paramDtl.parameterDtlDesc === null || paramDtl.parameterDtlDesc === "")) {
            result = "";
        }else{
            result = paramDtl.parameterDtlDesc??"";
        }
        dtl.vatTransactionType = result;
        if(!dtl.invoiceNo){
            const paramDtls = (await parameterDetailRepo.getParameterThirdDtlByParaCodeAndParaDtlCode(parameterCode,dtlCode));
            for(const para of paramDtls){
                if(ParameterDetailCodeValue.TAX_PERCENTAGE === para.parameterDtlCode && "Y" === para.activeInd && para.parameterDtlDesc){
                    const regex = /[^0-9.]/g; 
                    percentage = parseFloat(para.parameterDtlDesc.replace(regex, '').trim());
                    dtl.vatPercent = percentage;
                    dtl.adjPercentage = percentage;
                }
            }
        }
        const isUpdate = true;
        let noRecordFound = false;
        if(dtl.vatTariffType && dtl.vatTariffCode){
            const tarCoa = await getActiveTarffCoa(dtl.vatTariffType,dtl.vatTariffCode);
            const coas = await getCoa(tarCoa,dtl.countryCode??"");
            if (isUpdate) {
                if(tarCoa){
                    if(coas && coas.length >= 1){
                        // vatCoaInfoUpdate(coas[0]);
                        dtl.vatRevenueACCode = coas[0].revenueAccountCode;
                        dtl.vatCostCenterCode = coas[0].costCenterCode;
                        dtl.vatProductServiceCode = coas[0].productServiceCode;
                        dtl.vatSalesChannelCode = coas[0].salesChannelCode;
                        dtl.vatProjectCode = coas[0].projectCode;
                        dtl.vatSpareCode = coas[0].spareCode;
                        const interCompanyCodes: string[] = [];
                        coas.forEach((coa) => {
                            if(coa.intercompanyCode && !interCompanyCodes.includes(coa.intercompanyCode)){
                                interCompanyCodes.push(coa.intercompanyCode);
                            }
                        });
                        if(interCompanyCodes && interCompanyCodes.length === 1){
                            // setInterCompanyCode(interCompanyCodes[0]);
                            dtl.vatIntercompanyCode = interCompanyCodes[0];  
                        }
                    }else{
                        noRecordFound = true;
                    }
                }else{
                    noRecordFound = true;
                }
                if (noRecordFound) {
                    // coaInfoUpdate(EMPTY_COA_ENTITY);
                    dtl.vatRevenueACCode = "";
                    dtl.vatCostCenterCode = "";
                    dtl.vatProductServiceCode= "";
                    dtl.vatSalesChannelCode= "";
                    dtl.vatProjectCode = "";
                    dtl.vatSpareCode = "";
                }
            }
        }else{
            dtl.vatRevenueACCode = "";
            dtl.vatCostCenterCode = "";
            dtl.vatProductServiceCode= "";
            dtl.vatSalesChannelCode= "";
            dtl.vatProjectCode = "";
            dtl.vatSpareCode = "";
        }


        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    currentCreditNoteDtl: dtl,
                    // isRead:true,
                }, 
            }
        }); 
    }

    const vatCoaInfoUpdate = (coa: CoaEntity | null) => {
        if(coa && coa.id){
            creditNoteDetailDispatch(prevState => {
                return {
                    ...prevState,
                    creditNoteDetailState: {  
                        ...prevState.creditNoteDetailState, 
                        currentCreditNoteDtl: {  
                            ...prevState.creditNoteDetailState.currentCreditNoteDtl,  
                            vatRevenueACCode: coa.revenueAccountCode,  
                            vatCostCenterCode: coa.costCenterCode,  
                            vatProductServiceCode: coa.productServiceCode,  
                            vatSalesChannelCode: coa.salesChannelCode,  
                            vatProjectCode: coa.projectCode,  
                            vatSpareCode: coa.spareCode,  
                            vatIntercompanyCode: coa.intercompanyCode,
                          }, 
                    }, 
                }
            }); 
        }else{
            creditNoteDetailDispatch(prevState => {
                return {
                    ...prevState,
                    creditNoteDetailState: {  
                        ...prevState.creditNoteDetailState, 
                        currentCreditNoteDtl: {  
                            ...prevState.creditNoteDetailState.currentCreditNoteDtl,  
                            vatRevenueACCode: null,  
                            vatCostCenterCode: null,  
                            vatProductServiceCode: null,  
                            vatSalesChannelCode: null,  
                            vatProjectCode: null,  
                            vatSpareCode: null,  
                            vatIntercompanyCode: null,
                          }, 
                    }, 
                }
            }); 
        }
    }

    const onDelete = async (listSelectedDetails: CreditNoteDetailEntity[],listSelectedDiscounts: CreditNoteDetailEntity[]) => {
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    isShowDetailInfo: false,
                }, 
            }
        }); 
        let deleteCreditNoteDetail:DeleteCreditNoteDetail = {
            listSelectedDetails:listSelectedDetails,
            listSelectedDiscounts: listSelectedDiscounts,
        }
        return await creditNoteDetailRepo.deleteCreditDtls(deleteCreditNoteDetail);
    }
    
    const onApply = async (dtls: CreditNoteDetailEntity[]) => {
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    isShowDetailInfo: false,
                }, 
            }
        }); 
        let ids:number[] = [];
        if(dtls && dtls.length > 0){
            dtls.forEach(dtl =>{
                ids.push(dtl.id);
            })
        }
        let success = false;
        await creditNoteDetailRepo.apply(ids).then((data) =>{
            if(data){
                creditNoteDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        creditNoteDetailState: {  
                            ...prevState.creditNoteDetailState, 
                            isAdd: false,
                            isEditable: true,
                            isRead: true,
                        }, 
                    }
                }); 
                success = data;
            }
        });
        return success;
    }

    const initCustomerCodeByBillTo = async (billToCompany: string) =>{
        let customerSearchCriteria : CustomerSearchCriteria = {
            effectiveDate:  null,
            customerCode:  null,
            companyCode: billToCompany,
            filterCustomer: true,
            retrieveActiveCustomer: true,
            companyId: null,
        };
        let customerList: CustomerEntity[] = [];
        await customerRepo.getCustomersByCompany(customerSearchCriteria).then(
            customers => {
                customers.forEach((c) =>{
                    if(c.customer){
                        customerList.push(c.customer);
                    }
                });
            }
        )
        let customerCodeDropdownOptions = customerList?.map((customer) => ({
            dropdownLabel: customer.customerCode,
            tagLabel: customer.customerCode,
            value: customer.customerCode,
        })) ?? []

        creditNoteDetailDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                customerCodeDropdownOptions: customerCodeDropdownOptions,
            }
        }))
    }

    const onTitleBarCloseClick = () =>{
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    // currentCreditNoteDtl:prevState.creditNoteDetailState.currentCreditNoteDtl,
                    isAdd: false,
                    isEditable: true,
                    isRead: true,
                    isShowDetailInfo: false
                }, 
            }
        }); 
    }

    const onHeaderReset = () =>{
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    editingHeader: prevState.currentCreditNoteHdr
                }, 
            }
        }); 
    }

    const onHeaderEdit = (currCreditNoteHdr: CreditNoteHeaderEntity) =>{
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    isEditable: true,
                    isRead: false,
                    editingHeader: {
                        ...currCreditNoteHdr}
                }, 
            }
        }); 
    }

    const onByInvoiceClick = () =>{
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                selectInvoiceRecords: [],
                selectedcreditNoteDetailRows: [],
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    isShowInvoicePanel: true,
                    isSearchInvoice: true,
                    isAdd: true,
                    isRead: false,
                    isEditable: true,
                    isByInvoice: true,
                }, 
            }
        }); 
    }

    const onInvoicePanelClose = () =>{
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                selectInvoiceDtlRecords: [],
                selectInvoiceRecords: [],
                invoiceRecordList: [],
                invoiceRecordCriteria: {...EMPTY_INVOCIE_RECORD_SEARCH_CRITERIA},
                creditNoteDetailState: {  
                    ...prevState.creditNoteDetailState, 
                    isShowInvoicePanel: false,
                    isShowTariffItemPanel: false,
                }, 
            }
        }); 
    }
    
    const initCustomerCode = async () =>{
        await customerRepo.getAllCustomers().then(
            customers => {
                let customerCodeDropdownOptions = customers?.map((customer) => ({
                    dropdownLabel: customer.customerCode,
                    tagLabel: customer.customerCode,
                    value: customer.customerCode,
                })) ?? []

                creditNoteDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        customerCodeDropdownOptions: customerCodeDropdownOptions,
                    }
                }))
            }
        )
    }

    const handleInvoiceRowClick = async (dtl: CreditNoteInvoiceRecordEntity) =>{
        if(dtl.id){
            await creditNoteDetailRepo.getDocDtl(dtl.id).then((data) =>{
                creditNoteDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        invoiceDtlRecordList: data,
                    }
                })
            })
        }
    }

    const getInvoiceRecords = async(invoiceRecordCriteria: InvoiceRecordSearchCriteria) =>{
        creditNoteDetailDispatch(prevState => {
            return {
                ...prevState,
                invoiceRecordCriteria:invoiceRecordCriteria,
                selectInvoiceDtlRecords:[],
                invoiceDtlRecordList:[],
            }
        }); 
        await creditNoteDetailRepo.getInvoiceRecords(invoiceRecordCriteria).then((data) =>{
            creditNoteDetailDispatch(prevState => {
                return {
                    ...prevState,
                    invoiceRecordList: data,
                    invoiceRecordCriteria:invoiceRecordCriteria,
                    creditNoteDetailState:{
                        ...prevState.creditNoteDetailState,
                        isSearchInvoice: false,
                    }
                }
            })
        })
    }

    const onAddDtlByInvoiceApply = async (creditNoteHdr: CreditNoteHeaderEntity, creditNoteDtls:CreditNoteDetailEntity[],docHdrId: number,dtls: CreditNoteInvoiceDtlRecordEntity[]) =>{
        let request = {
            docHdrId:docHdrId,
            dtls: dtls,
            creditNoteDtls:creditNoteDtls,
            creditNoteHdrId: creditNoteHdr?.id,
        };
        let msg = "success";
        return await creditNoteDetailRepo.AddDtlByInvoiceApply(request).then(async (res) =>{
            if(res.success){
                if(res.data.isSingle === "N"){
                    const hdr = res.data.newHdrVo;
                    await onSearch(hdr);
                    creditNoteDetailDispatch(prevState => {
                        onApply(prevState.creditNoteDetails);
                        return {
                            ...prevState,
                            currentCreditNoteHdr: hdr,
                            invoiceRecordCriteria: {...EMPTY_INVOCIE_RECORD_SEARCH_CRITERIA},
                            creditNoteDetailState: {  
                                ...prevState.creditNoteDetailState, 
                                isShowDetailInfo: false,
                                isShowInvoicePanel:false,
                                isByInvoice: false,
                                isRead:true,
                                isAdd:false,
                                // isEditable:false,
                                editingHeader:hdr,
                            }, 
                        }
                    })
                    return msg;
                }else{
                    const hdr = res.data.newHdrVo;
                    const dtl = res.data.newDtlVos[0];
                    let newHdr = {...EMPTY_CREDIT_NOTE_HEADER_ENTITY};
                    newHdr = {...newHdr,
                        billToCompany:hdr.billToCompany,
                        customerCode:hdr.customerCode,
                        currencyCode:hdr.currencyCode,
                        consortiumCode:hdr.consortiumCode,
                        operatingTml:hdr.operatingTml,
                        vesselCode:hdr.vesselCode,
                        voyageCode:hdr.voyageCode,
                        vesselName:hdr.vesselName,
                        handlingTerminal:hdr.handlingTerminal,
                        slVesselCode:hdr.slVesselCode,
                        slIbVoyageCode:hdr.slIbVoyageCode,
                        slObVoyageCode:hdr.slObVoyageCode,
                        etd:hdr.etd,


                    };
                    let newDtl = {...EMPTY_CREDIT_NOTE_DETAIL_ENTITY};
                    newDtl = {...newDtl,
                        invoiceNo:dtl.invoiceNo,
                        oriChargingQty:dtl.oriChargingQty,
                        individualChargeQtyUom:dtl.individualChargeQtyUom,
                        oriAmount:dtl.oriAmount,
                        tariffType:dtl.tariffType,
                        tariffCode:dtl.tariffCode,
                        tarDesc:dtl.tarDesc,
                        opsDate:dtl.opsDate,
                        revenueAccountCode:dtl.revenueAccountCode,
                        costCenterCode:dtl.costCenterCode,
                        productServiceCode:dtl.productServiceCode,
                        salesChannelCode:dtl.salesChannelCode,
                        countryCode:dtl.countryCode,
                        intercompanyCode:dtl.intercompanyCode,
                        projectCode:dtl.projectCode,
                        spareCode:dtl.spareCode,
                        revenueCompanyCode:dtl.revenueCompanyCode,
                        adjType:dtl.adjType,
                        creditChargingQty:dtl.creditChargingQty,
                        dtlDesc1:dtl.dtlDesc1,
                        dtlDesc2:dtl.dtlDesc2,
                        vatRevenueACCode:dtl.vatRevenueACCode,
                        vatCostCenterCode:dtl.vatCostCenterCode,
                        vatProductServiceCode:dtl.vatProductServiceCode,
                        vatSalesChannelCode:dtl.vatSalesChannelCode,
                        vatCountryCode:dtl.vatCountryCode,
                        vatIntercompanyCode:dtl.vatIntercompanyCode,
                        vatProjectCode:dtl.vatProjectCode,
                        vatSpareCode:dtl.vatSpareCode,
                        vatCompanyCode:dtl.vatCompanyCode,
                        vatTransactionType:dtl.vatTransactionType,
                        vatPercent:dtl.vatPercent,
                        vatDays:dtl.vatDays,
                        vatArticleStatement:dtl.vatArticleStatement,
                        taxCode:dtl.taxCode,
                        vatDateType:dtl.vatDateType,
                        vatArticleStatementCode:dtl.vatArticleStatementCode,
                        vatDtlDesc1:dtl.vatDtlDesc1,
                        vatDtlDesc2:dtl.vatDtlDesc2,
                        vatTariffType:dtl.vatTariffType,
                        vatTariffCode:dtl.vatTariffCode,
                        docDtlId: dtl.docDtlId,
                        docDtlDataId: dtl.docDtlDataId,
                        discountAdjIds: dtl.discountAdjIds,
                        isDiscountItem: dtl.isDiscountItem,
                    }
                    creditNoteDetailDispatch(prevState => {
                        newDtl = {...newDtl,
                            reasonCode:prevState.dynamicOptions.reasonCodeDropdownOptions[0].value,
                        }
                        return {
                            ...prevState,
                            invoiceRecordCriteria: {...EMPTY_INVOCIE_RECORD_SEARCH_CRITERIA},
                            selectInvoiceDtlRecords:[],
                            creditNoteDetailState: {  
                                ...prevState.creditNoteDetailState, 
                                editingHeader: newHdr,
                                currentCreditNoteDtl: newDtl,
                                isShowDetailInfo: true,
                                isShowInvoicePanel:false,
                                isByInvoice: true,
                                isShowTariffItemPanel: false,
                            }, 
                        }
                    })
                    return msg;
                }
            }else{
                return res.data;
            }
        });
    }

    const onDocNoTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        creditNoteDetailDispatch(prevState => ({
            ...prevState,
            invoiceRecordCriteria: {
                ...prevState.invoiceRecordCriteria,
                invoiceNo: e.target.value
            }
        }))
    }

    const invoiceSearchReset = () => {
        creditNoteDetailDispatch(prevState => ({
            ...prevState,
            invoiceRecordCriteria: {...EMPTY_INVOCIE_RECORD_SEARCH_CRITERIA}
        }))
    }

    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        creditNoteDetailDispatch(prevState => ({
            ...prevState,
            invoiceRecordCriteria: {
                ...prevState.invoiceRecordCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    
    
    return {
        loadDropdownOption: loadDropdownOption,
        onFieldChange: onFieldChange,
        onTextAreaChange: onTextAreaChange,
        onCoVslVoyChange: onCoVslVoyChange,
        onHeaderFieldChange: onHeaderFieldChange,
        onAdd: onAdd,
        onCloseClick: onCloseClick,
        updateSelectedCharges: updateSelectedCharges,
        onPageInit: onPageInit,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onSearch: onSearch,
        onTariffCodeItemChanged: onTariffCodeItemChanged,
        onGetUom: onGetUom,
        onAdjTypeItemChanged: onAdjTypeItemChanged,
        onCheckboxChange: onCheckboxChange,
        onDetailAddClick: onDetailAddClick,
        onCancelClick: onCancelClick,
        onRefreshReqNo: onRefreshReqNo,
        onRowClick: onRowClick,
        onSaveClick: onSaveClick,
        getActiveTarffCoa: getActiveTarffCoa,
        getCoa: getCoa,
        coaInfoUpdate: coaInfoUpdate,
        vatCoaInfoUpdate: vatCoaInfoUpdate,
        getCustomersByCompany: getCustomersByCompany,
        setInterCompanyCode: setInterCompanyCode,
        refreshDefaultTaxCode: refreshDefaultTaxCode,
        // refreshVATInfoByTaxCode: refreshVATInfoByTaxCode,
        // refreshDefaultTarCode: refreshDefaultTarCode,
        getTaxCode: getTaxCode,
        onCoaInfoUpdate:onCoaInfoUpdate,
        onApply: onApply,
        onDelete: onDelete,
        initCustomerCode: initCustomerCode,
        initCustomerCodeByBillTo: initCustomerCodeByBillTo,
        onHeaderEdit: onHeaderEdit,
        // onDetailEdit: onDetailEdit,
        onTitleBarCloseClick:onTitleBarCloseClick,
        onTitleSaveClick: onTitleSaveClick,
        onTempSaveClick: onTempSaveClick,
        onHeaderReset: onHeaderReset,
        onByInvoiceClick: onByInvoiceClick,
        onInvoicePanelClose: onInvoicePanelClose,
        getInvoiceRecords: getInvoiceRecords,
        updateSelectedInvoiceRecordCharges: updateSelectedInvoiceRecordCharges,
        handleInvoiceRowClick: handleInvoiceRowClick,
        updateSelectedInvoiceDtlRecordCharges: updateSelectedInvoiceDtlRecordCharges,
        onAddDtlByInvoiceApply: onAddDtlByInvoiceApply,
        onDateRangeChange: onDateRangeChange,
        onDocNoTextInputChange: onDocNoTextInputChange,
        invoiceSearchReset: invoiceSearchReset,
    }
}