import { EMPTY_CREDIT_NOTE_HEADER_MAINTENANCE_MODEL } from "presentation/model/CreditNote/CreditNoteHeaderMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: CreditNoteHeaderMaintenanceProvider,
    useTracked: useCreditNoteHeaderMaintenanceTracked
} = createContainer(() => useState(EMPTY_CREDIT_NOTE_HEADER_MAINTENANCE_MODEL), {concurrentMode: true});
export { CreditNoteHeaderMaintenanceProvider, useCreditNoteHeaderMaintenanceTracked };

