import { useCreditNoteDetailTracked } from "presentation/store/CreditNote/CreditNoteDetailProvider";
import { memo, useMemo } from "react";
import { GetRecordsByInvoiceNoTablePanel } from "./GetRecordsByInvoiceNoTablePanel";
import GetRecordsByInvoiceNoTitleBar from "./GetRecordsByInvoiceNoTitleBar";
import { TariffItemsByInvoiceTablePanel } from "./TariffItemsByInvoiceTablePanel";

const InvoiceRecordsRightPanel = () => {
    const [ creditNoteDetailState ] = useCreditNoteDetailTracked();
    const {isShowTariffItemPanel} = creditNoteDetailState.creditNoteDetailState;

    const memoTariffItemPanel = useMemo(() => {
        return <TariffItemsByInvoiceTablePanel/>;
    }, [])
    const memoRecordsByInvoicePanel = useMemo(() => {
        return <GetRecordsByInvoiceNoTablePanel/>;
    }, [])

    return <>
        <div className={`main-comp-wrapper`} style={{height:"79vh", maxHeight:"79vh", overflow:"auto"}}>
        <GetRecordsByInvoiceNoTitleBar />
            {memoRecordsByInvoicePanel}
            
            {<div className={`child-div${isShowTariffItemPanel? " child-div-expanded " : ""}`}>
                {memoTariffItemPanel}
            </div>}
        </div>
    </>
}

export default memo(InvoiceRecordsRightPanel);