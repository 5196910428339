import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { CreditNoteDetailProvider } from "presentation/store/CreditNote/CreditNoteDetailProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import CreditNoteDetailMaintenance from "presentation/view/section/CreditNote/Detail/CreditNoteDetailMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const CreditNoteDetailContMain = () => {
    return <ANAInfoWrapper permission={Permission.CREDIT_NOTE_DETAIL}>
        <MessageBarWrapper>
            <CreditNoteDetailProvider>
                <GridStyles/>
                <CreditNoteDetailMaintenance/>
            </CreditNoteDetailProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default CreditNoteDetailContMain;