import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { AddDtlByInvoiceApplyRequest } from "domain/entity/CreditNote/AddDtlByInvoiceApplyRequest";
import { CreditNoteDetailEntity } from "domain/entity/CreditNote/CreditNoteDetailEntity";
import { CreditNoteInvoiceDtlRecordEntity } from "domain/entity/CreditNote/CreditNoteInvoiceDtlRecordEntity";
import { CreditNoteInvoiceRecordEntity } from "domain/entity/CreditNote/CreditNoteInvoiceRecordEntity";
import { InvoiceRecordSearchCriteria } from "domain/entity/CreditNote/InvoiceRecordSearchCriteria";
import { DeleteCreditNoteDetail } from "domain/entity/CreditNote/NewCreditNoteData";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { CreditNoteDetailRepository } from "./CreditNoteDetailRepo";

export const CreditNoteDetailRepoImpl = () : CreditNoteDetailRepository => {
    const url = "/v1/getCreditNoteDetailsByHdrId"
    const getCreditNoteDetailsByHdrId = async (creHdrId: number) : Promise<CreditNoteDetailEntity[]> => {
        return axiosGetData(chargeAxiosInstance, `${url}/${creHdrId}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }
    const apply = async (creDtlIds: number[]) : Promise<boolean> => {
        const newUrl = "/v1/creditChargePreprocess"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, creDtlIds).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }
    const deleteCreditDtls = async (deleteCreditNoteDetail:DeleteCreditNoteDetail) : Promise<ResponseEntity> => {
        const newUrl = "/v1/deleteCreditDtls"
        try {
            return await axiosPostData(chargeAxiosInstance, `${newUrl}`, deleteCreditNoteDetail) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
    }
    const getInvoiceRecords = async (invoiceRecordCriteria: InvoiceRecordSearchCriteria) : Promise<CreditNoteInvoiceRecordEntity[]> => {
        const newUrl = "/v1/getInvoiceRecords"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, invoiceRecordCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }
    const getDocDtl = async (docHdrId:number) : Promise<CreditNoteInvoiceDtlRecordEntity[]> => {
        const newUrl = "/v1/getDocDtl"
        return axiosGetData(chargeAxiosInstance, `${newUrl}/${docHdrId}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }
    const AddDtlByInvoiceApply = async (request: AddDtlByInvoiceApplyRequest) : Promise<ResponseEntity> => {
        const newUrl = "/v1/addDtlByInvoiceApply"
        // return axiosPostData(tempChargeAxiosInstance, `${newUrl}`, request).then(res => {
        //     const data = res.data;
        //     return data;
        // }).catch(err => {
        //     return null;
        // });
        try {
            return await axiosPostData(chargeAxiosInstance, `${newUrl}`, request) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
    }

    return {
        getCreditNoteDetailsByHdrId: getCreditNoteDetailsByHdrId,
        apply: apply,
        deleteCreditDtls: deleteCreditDtls,
        getInvoiceRecords: getInvoiceRecords,
        getDocDtl: getDocDtl,
        AddDtlByInvoiceApply: AddDtlByInvoiceApply,
    }
}
