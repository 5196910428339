export interface CreditNoteHeaderSearchCriteria{
    
    states?: string[] | null,
    billToCompanies?: string[] | null,
    reqNoFrom?: string | null,
    reqNoTo?: string | null,
    co?: string | null,
    vsl?: string | null,
    voy?: string | null,
    creditNoteNo?: string | null,
    originalInvoiceNo?: string | null,
    creditChargeNoFrom?: string | null,
    creditChargeNoTo?: string | null,
    createdBy?: string | null,
    createdDateFrom?: Date | null,
    createdDateTo?: Date | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_CREDIT_NOTE_HEADER_SEARCH_CRITERIA : CreditNoteHeaderSearchCriteria = {

    states: [],
    billToCompanies: [],
    reqNoFrom: null,
    reqNoTo: null,
    co: null,
    vsl: null,
    voy: null,
    creditNoteNo: null,
    originalInvoiceNo: null,
    creditChargeNoFrom: null,
    creditChargeNoTo: null,
    createdBy: "",
    createdDateFrom: null,
    createdDateTo: null,
}