export interface CreditNoteDetailEntity {
    id: number,
    docDtlId: number | null,
    creditDetailNo: number | null,
    dtlState: string,
    invoiceNo: string | null,
    oriChargingQty: number | null,
    oriAmount: number | null,
    creditChargingQty: number | null,
    creditAmount: number | null,
    tariffType: string | null,
    tariffCode: string | null,
    tarDesc: string | null,
    dtlDesc1: string | null,
    dtlDesc2: string | null,
    opsDate: Date | null,
    adjType: string | null,
    creditNoteNature: string | null,
    revenueAccountCode: string | null,
    costCenterCode: string | null,
    productServiceCode: string | null,
    salesChannelCode: string | null,
    countryCode: string | null,
    intercompanyCode: string | null,
    projectCode: string | null,
    spareCode: string | null,
    revenueCompanyCode: string | null,
    taxCode: string | null,
    vatPercent: number | null,
    vatRevenueACCode: string | null,
    vatCostCenterCode: string | null,
    vatProductServiceCode: string | null,
    vatSalesChannelCode: string | null,
    vatCountryCode: string | null,
    vatIntercompanyCode: string | null,
    vatProjectCode: string | null,
    vatSpareCode: string | null,
    vatCompanyCode: string | null,
    vatTransactionType: string | null,
    vatDays: number | null,
    vatDateType: string | null,
    vatArticleStatementCode: string | null,
    vatArticleStatement: string | null,
    vatDtlDesc1: string | null,
    vatDtlDesc2: string | null,
    creditNoteNo: string | null,
    draftCreditNoteNo: string | null,
    exceptionMsg: string | null,
    poNo: string | null,
    slVesselCode: string | null,
    slIbVoyageCode: string | null,
    slObVoyageCode: string | null,
    vatTariffType: string | null,
    vatTariffCode: string | null,
    vatTariffCodeDesc: string | null,
    chargeInd: string | null,
    itemSeq: number | null,
    individualChargeQtyUom: string | null,
    reasonCode: string | null,
    rebate: boolean | null,
    recalDocDtl: string | null,
    isDiscountItem: boolean,
    adjPercentage: number | null,
    qty: number | null,
    additionalChargeQty: number | null,
    storageFromDateCal: Date | null,
    isInputAmountInd: string | null,
    docAdjType: string | null,
    adjAmount: number | null,
    rebateInd: string | null,
    creditItemId: number | null,
    itemCreditChargeDtlId: number | null,
    discountAdjIds: number[] | null,
    AdjDtlsIds: number[],
    dummyKey: string | null,
    creditDtlDataId: number | null,
    docDtlDataId: number | null,

    [key: string]: string | Date | boolean | null | Object | undefined
}

export const EMPTY_CREDIT_NOTE_DETAIL_ENTITY : CreditNoteDetailEntity = {
    id: 0,
    dtlState: "E",
    docDtlId: null,
    creditDetailNo: null,
    invoiceNo: null,
    oriChargingQty: null,
    oriAmount: null,
    creditChargingQty: null,
    creditAmount: null,
    tariffType: null,
    tariffCode: null,
    tarDesc: null,
    dtlDesc1: null,
    dtlDesc2: null,
    opsDate: null,
    adjType: null,
    creditNoteNature: null,
    revenueAccountCode: null,
    costCenterCode: null,
    productServiceCode: null,
    salesChannelCode: null,
    countryCode: null,
    intercompanyCode: null,
    projectCode: null,
    spareCode: null,
    revenueCompanyCode: null,
    taxCode: null,
    vatPercent: null,
    vatRevenueACCode: null,
    vatCostCenterCode: null,
    vatProductServiceCode: null,
    vatSalesChannelCode: null,
    vatCountryCode: null,
    vatIntercompanyCode: null,
    vatProjectCode: null,
    vatSpareCode: null,
    vatCompanyCode: null,
    vatTransactionType: null,
    vatDays: null,
    vatDateType: null,
    vatArticleStatementCode: null,
    vatArticleStatement: null,
    vatDtlDesc1: null,
    vatDtlDesc2: null,
    creditNoteNo: null,
    draftCreditNoteNo: null,
    exceptionMsg: null,
    poNo: null,
    slVesselCode: null,
    slIbVoyageCode: null,
    slObVoyageCode: null,
    vatTariffType: null,
    vatTariffCode: null,
    vatTariffCodeDesc: null,
    chargeInd: null,
    itemSeq: null,
    individualChargeQtyUom: null,
    reasonCode: null,
    rebate: null,
    recalDocDtl: null,
    isDiscountItem: false,
    adjPercentage: null,
    isInputAmountInd: null,
    qty: null,
    additionalChargeQty: null,
    storageFromDateCal: null,
    docAdjType: null,
    adjAmount: null,
    rebateInd: "N",
    creditItemId: null,
    itemCreditChargeDtlId: null,
    discountAdjIds: null,
    dummyKey: null,
    AdjDtlsIds: [],
    creditDtlDataId: null,
    docDtlDataId: null,
}