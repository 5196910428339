import { CoaRepoImpl } from "domain/repository/Company/CoaRepoImpl";
import { CustomerRepoImpl } from "domain/repository/Company/CustomerRepoImpl";
import { CreditNoteDetailRepoImpl } from "domain/repository/CreditNote/CreditNoteDetailRepoImpl";
import { CreditNoteHeaderRepoImpl } from "domain/repository/CreditNote/CreditNoteHeaderRepoImpl";
import { TariffCoaMappingRepoImpl } from "domain/repository/Document/TariffCoaMappingRepoImpl";
import { ExchangeRateRepoImpl } from "domain/repository/ExchangeRate/ExchangeRateRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { RequisitionFormRepoImpl } from "domain/repository/RequisitionForm/RequisitionFormRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffProposalRepoImpl } from "domain/repository/TariffCode/TariffProposalRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useCreditNoteDetailTracked } from "presentation/store/CreditNote/CreditNoteDetailProvider";
import { useCreditNoteHeaderMaintenanceTracked } from "presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider";
import { CreditNoteDetailVM } from "presentation/viewModel/CreditNote/CreditNoteDetailVM";
import { useMemo } from "react";

export const useCreditNoteDetailVM = () => {
    const [, setCreditNoteDetailState] = useCreditNoteDetailTracked();
    const [, setCreditNoteHeaderState] = useCreditNoteHeaderMaintenanceTracked();
    const creditNoteDetailVM = useMemo(() =>
    CreditNoteDetailVM({
            dispatch: [setCreditNoteDetailState],
            hdrDispatch: [setCreditNoteHeaderState],
            creditNoteDetailRepo: CreditNoteDetailRepoImpl(),
            creditNoteHeaderRepo: CreditNoteHeaderRepoImpl(),
            standardTariffCodeRepo:StandardTariffCodeRepoImpl(),
            customerRepo:CustomerRepoImpl(),
            exchangeRateRepo:ExchangeRateRepoImpl(),
            requisitionFormRepo:RequisitionFormRepoImpl(),
            tariffProposalRepo:TariffProposalRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            tariffCoaMappingRepo: TariffCoaMappingRepoImpl(),
            coaRepo: CoaRepoImpl(),

        }), [setCreditNoteDetailState,setCreditNoteHeaderState])

    return creditNoteDetailVM
}

